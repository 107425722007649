import AddIcon from '@mui/icons-material/Add';
import { Button, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AdminLayout } from 'components/organisms';
import CreateFilterModal from 'components/organisms/CreateFilterModal';
import { useDisclosure } from 'constants/hooks/useDisclosure';
import { QUERY_CACHE } from 'constants/query';
import { Axios } from 'core/httpServices';
import { difference } from 'lodash';
import _get from 'lodash/get';
import { MRT_ColumnDef } from 'material-react-table';
import { CruProvider, useInitialValues, useShowCRUModal } from 'providers/context/modalContext';
import qs from 'qs';
import React, { useCallback, useMemo } from 'react';
import { FiArrowLeft, FiEdit2, FiTrash2 } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ADMIN_ROUTES } from 'routers';
import { generateShadowColor } from 'utils/color';
import { dateFormat } from 'utils/datetime';
import ConfirmModal from '../../molecules/ConfirmModal';
import PaginationTable from '../../molecules/PaginationTable';

interface AdminCriteriaProps {}

interface Principle {
	id: string;
	// principle: string
	name: string;
	image: any;
	description: string;
	created_at: string;
	color: string;
}

// const transformScoreGuideArrayToObject = (input: string[]) => {
//   return input?.reduce<{ [key: string]: string }>((acc, v: any, idx: number) => {
//     acc[`${idx + 1}`] = v;
//     return acc;
//   }, {});
// }

const transformScoreGuideObjectToArray = (input: { [key: string]: string }) => {
	return Object.values(input);
};

const AdminCriteria: React.FC<AdminCriteriaProps> = () => {
	const { id: criteriaId } = useParams();
	const queryClient = useQueryClient();
	const {
		isOpen: isOpenConfirmModal,
		item: confirmedItem,
		open: openConfirmModal,
		close: closeConfirmModal,
	} = useDisclosure();
	const { displayCRUModal, showCRU, closeCRUModal } = useShowCRUModal();
	const [initialValues] = useInitialValues();

	const { data: criteriaDetail } = useQuery(
		[QUERY_CACHE.INTRODUCTION, criteriaId],
		async () => {
			const data = await Axios.get(`tag-types/${criteriaId}?includes=tags`);
			return data.data;
		},
		{ keepPreviousData: true }
	);

	const { mutateAsync: createOrUpdatePrincipleAction } = useMutation(
		async ({ principle, action }: { principle: Principle; action: string }) => {
			const data = new FormData();

			const colorList = criteriaDetail?.color ? generateShadowColor(criteriaDetail?.color) : [];
			const usedColors = criteriaDetail?.tags?.map((it: any) => it.color);
			const targetColor = principle.color || difference(colorList, usedColors)[0] || colorList[0];
			data.append('color', targetColor);

			data.append('name', principle.name);
			data.append('description', principle.description);
			data.append('image', principle.image);
			if (action === 'update') {
				return Axios.put(`tag-types/${criteriaId}/tags/${principle.id}`, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				});
			}
			return Axios.post(`tag-types/${criteriaId}/tags`, data, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries({ queryKey: [QUERY_CACHE.INTRODUCTION] });
				closeCRUModal();
			},
			onError: error => {
				const errMessage = _get(error, 'response.data.error.message', '');
				toast.error(errMessage);
			},
		}
	);

	const { mutate: deletePrincipleAction } = useMutation(
		async (principle: Principle) => {
			return Axios.delete(`tag-types/${criteriaId}/tags/${principle.id}`, null);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries({ queryKey: [QUERY_CACHE.INTRODUCTION] });
				closeConfirmModal();
			},
			onError: error => {
				const errMessage = _get(error, 'response.data.error.message', '');
				toast.error(errMessage);
			},
		}
	);

	const fetchPrinciples = useCallback(({ pagination, search }: any) => {
		return Axios.get(
			`tag-types/${criteriaId}/tags?${qs.stringify({
				page: pagination.pageIndex,
				limit: pagination.pageSize,
				q: search,
				sort: 'created_at',
			})}`
		);
	}, []);

	const handleCreateUpdatePrinciple = useCallback(
		(values: any) => {
			return createOrUpdatePrincipleAction({ principle: values, action: values.id ? 'update' : 'create' });
		},
		[createOrUpdatePrincipleAction]
	);

	const handleDeletePrinciple = useCallback(
		(principle: Principle) => {
			deletePrincipleAction(principle);
		},
		[deletePrincipleAction]
	);

	const columns = useMemo<MRT_ColumnDef<Principle>[]>(
		() => [
			{
				accessorKey: 'name',
				header: 'Category',
				accessorFn: row => {
					return (
						<Box>
							<Typography sx={{ fontSize: 14, fontWeight: 500, color: '#101828' }}>{row.name}</Typography>
						</Box>
					);
				},
			},
			{
				header: 'Description',
				accessorFn: row => (
					<Typography
						sx={{
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							display: '-webkit-box',
							WebkitLineClamp: '2',
							WebkitBoxOrient: 'vertical',
						}}
					>
						{row.description}
					</Typography>
				),
			},
			{
				id: 'created_at',
				accessorKey: 'created_at',
				header: 'Date Created',
				size: 150,
				accessorFn: row => {
					return row.created_at && dateFormat(row.created_at);
				},
			},
			{
				id: 'action',
				header: '',
				size: 120,
				accessorFn: row => (
					<Box display="flex" gap="0.5rem">
						<IconButton onClick={() => openConfirmModal(row)}>
							<FiTrash2 fontSize="medium" />
						</IconButton>
						<IconButton
							onClick={() => {
								showCRU({
									...row,
									// scoring_guide: transformScoreGuideObjectToArray(row.scoring_guide as { [key: string]: string })
								});
							}}
						>
							<FiEdit2 fontSize="medium" />
						</IconButton>
					</Box>
				),
			},
		],
		[showCRU, openConfirmModal]
	);

	return (
		<AdminLayout>
			<Box height="calc(100vh - 100px)">
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'start',
						marginBottom: 2,
						paddingBottom: 3,
						borderBottom: '1px solid #EAECF0',
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Link to={ADMIN_ROUTES.INTRODUCTION}>
							<Button>
								<FiArrowLeft fontSize="24" color="black" />
							</Button>
						</Link>
						<Box>
							<Typography variant="h5" fontWeight="bold">
								{criteriaDetail?.name || ''}
							</Typography>
						</Box>
					</Box>
					<Button variant="contained" startIcon={<AddIcon />} onClick={() => showCRU()}>
						Add Category
					</Button>
				</Box>
				<PaginationTable
					dataKeys={[QUERY_CACHE.INTRODUCTION]}
					columns={columns}
					enableTopToolbar={false}
					searchPlaceholder="Search by name"
					remoteDataFn={fetchPrinciples}
				/>
			</Box>
			<CreateFilterModal
				isOpen={displayCRUModal}
				onCancel={closeCRUModal}
				onOk={handleCreateUpdatePrinciple}
				initialValues={initialValues}
			/>
			<ConfirmModal
				open={isOpenConfirmModal}
				title="Delete Category"
				subject={confirmedItem}
				renderMessage={(item: any) => (
					<Box>
						<Typography
							sx={{ mb: 2, fontSize: 18, fontWeight: 'bold', span: { color: theme => theme.palette.primary.main } }}
						>
							Are you sure you want to delete <span>{_get(item, 'name')}</span> ?
						</Typography>
						<Typography sx={{ color: theme => theme.palette.grey[500] }}>
							Do note that you cannot undo this action.
						</Typography>
					</Box>
				)}
				onCancel={closeConfirmModal}
				onOk={handleDeletePrinciple}
				okProps={{
					children: 'Delete',
				}}
			/>
		</AdminLayout>
	);
};

const AdminCriteriaProvider = (props: any) => {
	return (
		<CruProvider>
			<AdminCriteria {...props} />
		</CruProvider>
	);
};

export default AdminCriteriaProvider;
