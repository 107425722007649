import { Box, InputLabel } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import React from 'react';

interface Props {
	name: string;
	placeholder?: string;
	maxLength?: number;
	value?: string;
	label?: string;
	children?: any;
	error?: string;
	onChange?: (e: any) => void;
}

export const Textarea: React.FC<Props> = ({
	children,
	label,
	placeholder,
	name,
	maxLength,
	value,
	error,
	onChange,
}) => {
	const { handleBlur, handleChange } = useFormikContext<any>();
	const [field] = useField({ name });

	return (
		<Box sx={{ width: '100%' }}>
			{label && <InputLabel sx={{ marginBottom: 1 }}>{label}</InputLabel>}
			<textarea
				style={{
					width: '100%',
					boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
					border: '1px solid',
					borderColor: error ? 'red' : '#D0D5DD',
					borderRadius: '8px',
					minHeight: '100px',
					padding: '10px',
				}}
				className="a-textarea"
				placeholder={placeholder}
				name={name}
				onBlur={handleBlur}
				onChange={e => {
					handleChange(e);
					onChange && onChange(e);
				}}
				maxLength={maxLength}
				value={value}
			>
				{children}
			</textarea>
			{maxLength && (
				<Box className="character-left" mt={1} color="#475467" fontSize="14px">{`${
					field.value?.length || 0
				}/${maxLength}`}</Box>
			)}
			{!!error && (
				<Box mt={1} color="red">
					{error}
				</Box>
			)}
		</Box>
	);
};

export default Textarea;
