import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Chip, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AdminLayout } from 'components/organisms';
import { useDisclosure } from 'constants/hooks/useDisclosure';
import { QUERY_CACHE } from 'constants/query';
import { Axios } from 'core/httpServices';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import { MRT_ColumnDef } from 'material-react-table';
import qs from 'qs';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { dateFormat } from 'utils/datetime';
import ConfirmModal from '../../molecules/ConfirmModal';
import PaginationTable from '../../molecules/PaginationTable';
import UserModal from '../../organisms/UserModal';
import { CruProvider, useInitialValues, useShowCRUModal } from './ModalContext';

interface AdminUserProps {}

interface User {
	id: string;
	full_name: string;
	email: string;
	title: string;
	organization?: {
		id: string;
		name: string;
	};
	created_at: string;
	status: number;
}

const AdminCreateMeasure: React.FC<AdminUserProps> = () => {
	const queryClient = useQueryClient();
	const {
		isOpen: isOpenConfimModal,
		item: confirmedItem,
		open: openConfirmModal,
		close: closeConfirmModal,
	} = useDisclosure();
	const { displayCRUModal, showCRU, closeCRUModal } = useShowCRUModal();
	const [initialValues] = useInitialValues();

	const { mutateAsync: createOrUpdateUserAction } = useMutation(
		async ({ user, action }: { user: any; action: string }) => {
			if (action === 'update') {
				return Axios.put(`users/${user.id}`, _pick(user, ['full_name', 'title', 'organization_id']));
			}
			return Axios.post(`users`, user);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries({ queryKey: [QUERY_CACHE.MEASURE] });
				toast.success(`Measure ${variables.user.name} has been ${variables.action}d successfully`);
				closeCRUModal();
			},
			onError: error => {
				const errMessage = _get(error, 'response.data.error.message', '');
				toast.error(errMessage);
			},
		}
	);

	const { mutate: activeInactiveUserAction } = useMutation(
		async ({ userId, fullName, action }: { userId: string; fullName: string; action: string }) => {
			return Axios.put(`users/${userId}/${action}`, null);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries({ queryKey: [QUERY_CACHE.USERS] });
				toast.success(`Status of ${variables.fullName} has been updated successfully`);
			},
			onError: error => {
				const errMessage = _get(error, 'response.data.error.message', '');
				toast.error(errMessage);
			},
		}
	);

	const { mutate: resendEmailAction } = useMutation(
		async ({ email }: { email: string }) => {
			return Axios.post('auth/resend-verify-email', { email });
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries({ queryKey: [QUERY_CACHE.USERS] });
				toast.success(`Resent email to ${variables.email}`);
			},
			onError: error => {
				const errMessage = _get(error, 'response.data.error.message', '');
				toast.error(errMessage);
			},
		}
	);

	const { mutate: deleteMeasureAction } = useMutation(
		async (user: any) => {
			return Axios.delete(`measures/${user.id}`, null);
		},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries({ queryKey: [QUERY_CACHE.MEASURE] });
				toast.success(`Measure ${variables.name} has been deleted successfully`);
				closeConfirmModal();
			},
			onError: error => {
				const errMessage = _get(error, 'response.data.error.message', '');
				toast.error(errMessage);
			},
		}
	);

	const { data: organizationsData } = useQuery(
		[QUERY_CACHE.ORG],
		async () => {
			const data = await Axios.get(
				`/measures?page=1&limit=10&includes=applications,attachments,criterias,tags,location,potentials,description,criteria_types`
			);
			return data.data;
		},
		{ keepPreviousData: true }
	);

	const organizationsOptions = useMemo(() => {
		return (
			organizationsData &&
			organizationsData.data.map((item: any) => ({
				value: item.id,
				label: item.name,
			}))
		);
	}, [organizationsData]);

	const fetchUsers = useCallback(({ pagination, search }: any) => {
		return Axios.get(
			`/measures?includes=applications,attachments,criterias,tags,location,potentials,description,criteria_types&${qs.stringify(
				{
					page: pagination.pageIndex,
					limit: pagination.pageSize,
					q: search,
				}
			)}`
		);
	}, []);

	const handleCreateUpdateUser = useCallback(
		(values: any) => {
			return createOrUpdateUserAction({
				user: values,
				action: values.id ? 'update' : 'create',
			});
		},
		[createOrUpdateUserAction]
	);

	const handleDeleteUser = useCallback(
		(user: User) => {
			deleteMeasureAction(user);
		},
		[deleteMeasureAction]
	);

	const columns = useMemo<MRT_ColumnDef<any>[]>(
		() => [
			{
				accessorKey: 'full_name',
				header: 'Name',
				accessorFn: row => {
					return (
						<Box>
							<Typography sx={{ fontSize: 14, fontWeight: 500 }}>{row.name}</Typography>
						</Box>
					);
				},
			},
			{
				accessorFn: row => {
					return (
						<Box>
							<Chip
								label={row.category?.name}
								size="small"
								sx={{
									fontSize: 12,
									fontWeight: 500,
									mb: 1,
									background: '#F2F4F7',
								}}
							/>
						</Box>
					);
				},
				header: 'Functionality',
			},
			{
				accessorKey: 'email',
				accessorFn: row => {
					return row.tags
						.filter((e: any) => e.tag_type_name === 'Typology')
						.map((opt: any, index: number) => (
							<Box key={index}>
								<Chip
									label={opt.name}
									size="small"
									sx={{
										fontSize: 12,
										fontWeight: 500,
										mb: 1,
										background: '#F2F4F7',
									}}
								/>
							</Box>
						));
				},
				header: 'Typology',
			},
			{
				accessorKey: 'Profiles',
				accessorFn: row => {
					return row.tags
						.filter((e: any) => e.tag_type_name === 'Land Use Profiles')
						.map((opt: any, index: number) => (
							<Box>
								<Chip
									label={opt.name}
									size="small"
									sx={{
										fontSize: 12,
										fontWeight: 500,
										mb: 1,
										background: '#F2F4F7',
									}}
								/>
							</Box>
						));
				},
				header: 'Land Use Profiles',
			},
			{
				id: 'created_at',
				accessorKey: 'created_at',
				header: 'Date Created',
				size: 150,
				accessorFn: row => {
					return row.created_at && dateFormat(row.created_at);
				},
			},
			{
				id: 'action',
				header: '',
				size: 120,
				accessorFn: row => (
					<Box display="flex" gap="0.5rem">
						<IconButton onClick={() => openConfirmModal(row)}>
							<DeleteOutlinedIcon />
						</IconButton>
						<Link to={`/admin/editmeasure?id=${row.id}`}>
							<IconButton>
								<EditIcon />
							</IconButton>
						</Link>
					</Box>
				),
			},
		],
		[activeInactiveUserAction, resendEmailAction, showCRU, openConfirmModal]
	);

	return (
		<AdminLayout>
			<Box height="calc(100vh - 100px)">
				<Box display="flex" justifyContent="space-between" alignItems="start" mb={2}>
					<Box>
						<Typography variant="h5" fontWeight="bold">
							Measure Management
						</Typography>
					</Box>
					<Link to="/admin/createmeasure">
						<Button variant="contained" startIcon={<AddIcon />}>
							Add Measure
						</Button>
					</Link>
				</Box>
				<PaginationTable
					dataKeys={[QUERY_CACHE.MEASURE]}
					columns={columns}
					searchPlaceholder="Search by measure name"
					remoteDataFn={fetchUsers}
				/>
			</Box>
			<UserModal
				isOpen={displayCRUModal}
				onCancel={closeCRUModal}
				onOk={handleCreateUpdateUser}
				initialValues={initialValues}
				options={{
					organizations: organizationsOptions,
				}}
			/>
			<ConfirmModal
				open={isOpenConfimModal}
				title="Delete Measure"
				subject={confirmedItem}
				renderMessage={(item: any) => (
					<Box>
						<Typography
							sx={{
								mb: 2,
								fontSize: 18,
								fontWeight: 'bold',
								span: { color: theme => theme.palette.primary.main },
							}}
						>
							Are you sure you want to delete <span>{_get(item, 'name')}</span> ?
						</Typography>
						<Typography sx={{ color: theme => theme.palette.grey[500] }}>
							Do note that you cannot undo this action.
						</Typography>
					</Box>
				)}
				onCancel={closeConfirmModal}
				onOk={handleDeleteUser}
				okProps={{
					children: 'Delete',
				}}
			/>
		</AdminLayout>
	);
};

const AdminMeasureProvider = (props: any) => {
	return (
		<CruProvider>
			<AdminCreateMeasure {...props} />
		</CruProvider>
	);
};

export default AdminMeasureProvider;
