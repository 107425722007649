import HorizontalRuleRoundedIcon from '@mui/icons-material/HorizontalRuleRounded';
import { Typography } from '@mui/material';
import Rating from '@mui/material/Rating';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useFormikContext } from 'formik';
import * as React from 'react';

interface ratingProps {
	value: number;
	title?: string;
	readonly?: boolean;
	onChange?: any;
	ids?: any;
	name?: string;
}

const StyledRating = styled(Rating)(props => ({
	color: (function () {
		switch (Number(props.value)) {
			case 1:
				return '#FF5372';
			case 2:
				return '#FF8F28';
			case 3:
				return '#FDE600';
			case 4:
				return '#94C259';
			case 5:
				return '#179C01';
			default:
				return '';
		}
	})(),
	'&>*': {
		color: 'inherit',
	},
}));

const RatingCustomAdmin: React.FC<ratingProps> = ({ value, title, readonly = true, onChange, ids, name }) => {
	const { setFieldValue } = useFormikContext<any>();
	const valueFormik = (res: any, req: any) => {
		onChange(res, ids, name);
		setCurrentValue(res);
		setFieldValue(`${name}.criteria_id`, ids);
		setFieldValue(`${name}.point`, res);
	};
	const [currentValue, setCurrentValue] = React.useState(value);
	const [max, setMax] = React.useState(value || 1);

	const onMouseEnter = () => {
		if (readonly) return;
		setMax(5);
	};

	const onMouseLeave = (e: any) => {
		if (readonly) return;
		setMax(currentValue || 1);
		e.currentTarget.style.color = '';
	};

	const onMouseMove = (e: any) => {
		e.currentTarget.querySelectorAll('label').forEach((label: any, id: number) => {
			if (label === e.target) {
				const color = (function () {
					switch (id + 1) {
						case 1:
							return '#FF5372';
						case 2:
							return '#FF8F28';
						case 3:
							return '#FDE600';
						case 4:
							return '#94C259';
						case 5:
							return '#179C01';
						default:
							return '';
					}
				})();
				e.currentTarget.style.color = color;
			}
		});
	};

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: !title ? 'center' : 'space-between',
				my: 2,
				cursor: readonly ? 'default' : 'pointer',
			}}
		>
			{title && <Typography>{title}</Typography>}
			<StyledRating
				onChange={(event, newValue) => valueFormik(newValue, event)}
				name="customized-color"
				readOnly={readonly}
				value={currentValue}
				icon={
					<HorizontalRuleRoundedIcon
						className="hellooooo"
						fontSize="small"
						sx={{
							transform: 'scale(9, 4) rotate(90deg)',
							width: '10px',
						}}
					/>
				}
				emptyIcon={
					<HorizontalRuleRoundedIcon fontSize="small" sx={{ transform: 'scale(9, 4) rotate(90deg)', width: '10px' }} />
				}
				max={max}
				onMouseMove={onMouseMove}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			/>
		</Box>
	);
};

export default RatingCustomAdmin;
