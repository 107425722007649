import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material';
import { icon_addMore, icon_arrowLeft, icon_remove, icon_uploadPlus } from 'assets/icons';
import {
	FieldFormik,
	Fieldrow,
	FileInput,
	MultiSelect,
	RatingCustomAdmin,
	RatingEditAdmin,
	Select,
	TextEditor,
	Textarea,
} from 'components/atoms';
import { AdminLayout, ModalAdmin, ModalAdminCustom, ModalLocationEdit } from 'components/organisms';
import { createSchema, initialValue } from 'constants/form/measure-form';
import { Axios } from 'core/httpServices';
import { Field, FieldArray, Form, Formik } from 'formik';
import { pickBy } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ADMIN_ROUTES } from 'routers';

interface AdminCreateMeasureProps {}

const buttonStyling = {
	width: '100%',
	border: '1px dashed #E5E7EB',
	height: '110px',
};

const transformObjectToArray = (input: any) => {
	let c = [];
	for (let i = 0; i < input?.length; i++) {
		for (let e = 0; e < input[i]?.length; e++) {
			c.push(input[i][e]);
		}
	}
	return c;
};

const AdminEditMeasure: React.FC<AdminCreateMeasureProps> = ({}) => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const id_number = searchParams.get('id');
	const [getData, setGetdata] = useState<any>();
	const [resUseProfiles_tags, setResUseProfiles_tags] = useState<any>();
	const [functionality_tags, setFunctionality_tags] = useState<any>(null);

	const [modalOpen, setModalOpen] = useState(false);

	const [modalOpenLocation, setModalLocation] = useState(false);

	const [modalOpenSchematic, setModalOpenSchematic] = useState<number>();

	const [applicationId, setApplicationId] = useState(0);
	const [applicationDeletedIds, setApplicationDeletedIds] = useState<any[]>([]);
	const [filters, setFilters] = useState();
	const [getRes, setGetRes] = useState<any>();
	const [getCriteria, setGetCriteria] = useState<any>();

	const getDatalist = async () => {
		const getData = await Axios.get(`/tag-types/all?&includes=tags,criterias,location`);
		// const getCriteria = await Axios.get(`/criterias/all`)
		const useProfiles_tags = getData.data.data
			.filter((e: any) => e.slug === 'land_use_profiles')
			.map((req: any) => req.tags);
		setResUseProfiles_tags(useProfiles_tags[0]);
		setGetdata(getData.data.data);
	};

	const getCriteriaList = async () => {
		const getCriteria = await Axios.get(`/criteria-types?includes=criterias,criteria_types&sort=created_at`);
		setGetCriteria(getCriteria.data.data);
	};

	const getFunctionality_tags = async () => {
		try {
			const getTags = await Axios.get(`/measures?limit=200&sort=-created_at`);
			setFunctionality_tags(getTags.data.data.filter((it: any) => it.id !== id_number));
		} catch {
			console.log('error');
		}
	};

	const getMeasuresDetail = async () => {
		try {
			const getData = await Axios.get(
				`/measures/${id_number}?includes=applications,attachments,criterias,tags,location,potentials,description,criteria_types`
			);
			setGetRes(getData?.data);
		} catch {
			console.log('error');
		}
	};
	useEffect(() => {
		getDatalist();
		getMeasuresDetail();
		getCriteriaList();
		getFunctionality_tags();
	}, []);

	return (
		<AdminLayout>
			{/* ///////////// */}
			<section>
				<Formik
					enableReinitialize={true}
					initialValues={{
						...initialValue,
						measurename: getRes?.name,
						Functionality: getRes?.category_id,
						description: getRes?.description,
						useProfiles: getRes?.tags.filter((e: any) => e.tag_type_slug === 'land_use_profiles'),
						Typology:
							getRes?.tags
								.filter((e: any) => e.tag_type_slug !== 'land_use_profiles' && e.tag_type_slug !== 'functionality')
								.map((d: any) => d.id) || [],
						functions: getRes?.functions,
						application: getRes ? [...getRes.applications] : [],
						photoSchematic: getRes ? getRes.attachments : [],
						location: getRes?.location,
						scoringTechnicalPerformance: getRes ? [...getRes?.criterias.map((e: any) => e)] : [],
						scoringParentTechnicalPerformance: getRes ? [...getRes?.criteria_types] : [],
						potential: getRes?.potentials,
					}}
					validationSchema={createSchema}
					onSubmit={values => {}}
					validateOnMount
				>
					{({ values, isValid, setTouched, touched, setFieldValue, getFieldValue, handleChange }: any) => {
						const postData = async () => {
							const data = new FormData();
							const attachment = [...values.photoSchematic];
							if (attachment.filter(it => !it.delete).length > 6) {
								toast.error(
									'There is a maximum of 6 images allowed per measure. To continue, please remove the excess photos.'
								);
								return;
							}

							if (values.Typology?.length > 0) {
								const cleanedTypology = pickBy(values.Typology, v => v !== undefined);
								const TypologyArray = Object.values(cleanedTypology);
								if (values.Typology?.length === 1) {
									//@ts-ignore
									data.append('tag_ids[]', TypologyArray);
								} else if (values.Typology?.length > 1) {
									TypologyArray.forEach((element: any) => data.append('tag_ids', element));
								}
							}
							if (values.useProfiles?.length > 0) {
								const tag_type = [...values?.useProfiles.map((e: any) => e.id)];
								if (values.useProfiles?.length === 1) {
									//@ts-ignore
									data.append('tag_ids[]', tag_type);
								} else if (values.useProfiles?.length > 1) {
									tag_type.forEach((element: any) => data.append('tag_ids', element));
								}
							}

							if (values.potential) {
								let tag_potential = values?.potential.map((e: any) => e.id);
								if (tag_potential?.length === 1) {
									//@ts-ignore
									data.append('potential_ids[]', tag_potential);
								} else if (tag_potential?.length > 1) {
									tag_potential.forEach((element: any) => data.append('potential_ids', element));
								}
							}

							if (attachment.length > 0) {
								attachment
									.filter((e: any) => e.delete === true)
									.map((items: any) => items.id && data.append(`delete_attachment_ids[]`, items.id));
								const attachmentNotDelete = attachment.filter((e: any) => e.delete === undefined);
								for (let i = 0; i < attachmentNotDelete.length; i++) {
									if (
										attachmentNotDelete &&
										attachmentNotDelete[i]?.id !== undefined &&
										attachmentNotDelete[i].delete === undefined
									) {
										data.append(`attachments[${i}][name]`, attachmentNotDelete[i]?.name || 'No title');
										data.append(
											`attachments[${i}][image]`,
											attachmentNotDelete[i]?.file ? attachmentNotDelete[i]?.file : attachmentNotDelete[i]?.image
										);
										data.append(`attachments[${i}][id]`, attachmentNotDelete[i]?.id);
									} else {
										data.append(`attachments[${i}][name]`, attachmentNotDelete[i]?.name || 'No title');
										data.append(`attachments[${i}][image]`, attachmentNotDelete[i]?.file);
									}
								}
							}

							applicationDeletedIds.forEach(id => {
								data.append(`delete_application_ids[]`, id);
							});

							values.application
								?.filter((it: any) => !!it)
								?.forEach((it: any, i: number) => {
									if (it?.id) {
										data.append(`applications[${i}][id]`, it.id);
									}
									data.append(`applications[${i}][title]`, it.title || 'No title');
									data.append(`applications[${i}][description]`, it.description || 'No description');
									data.append(`applications[${i}][image]`, it.image);
								});

							// data.append('measure_id', values.measureID);
							data.append('category_id', values.Functionality);
							data.append('name', values.measurename);
							data.append('description', values.description);
							if (values.pdfFile) {
								data.append('pdf_file', values.pdfFile);
								data.append('pdf_key', 'pdf_key');
							}
							data.append('functions', values.functions);

							if (values.location) {
								if (values.location.delete && values.location.delete === true) {
									data.append('delete_location_id', values.location.id);
								} else {
									data.append('location[title]', values.location.title || 'No title');
									data.append('location[description]', values.location.description || 'No description');
									data.append('location[image]', values.location.file || values.location.image || '');
								}
							}
							if (values.scoringTechnicalPerformance) {
								for (var i = 0; i < values.scoringTechnicalPerformance.length; i++) {
									if (values.scoringTechnicalPerformance[i]) {
									} else {
										values.scoringTechnicalPerformance.splice(i, 1);
									}
								}

								const criteriaArray = transformObjectToArray(getCriteria?.map((e: any) => e.criterias));

								for (let i = 0; i < criteriaArray.length; i++) {
									const dataInput = values.scoringTechnicalPerformance?.find(
										(it: any) => it?.id === criteriaArray?.[i]?.id || it?.criteria_id === criteriaArray?.[i]?.id
									);
									data.append(
										`criterias[${i}][criteria_id]`,
										dataInput?.id || dataInput?.criteria_id || criteriaArray[i].id
									);
									data.append(
										`criterias[${i}][description]`,
										dataInput?.measure_criteria?.description || dataInput?.description || ''
									);
									data.append(`criterias[${i}][point]`, dataInput?.measure_criteria?.point ?? dataInput?.point ?? 0);
								}
							}

							getCriteria?.forEach((it: any, i: any) => {
								const dataInput = values?.scoringParentTechnicalPerformance?.find((item: any) => item.id === it.id);
								data.append(`criteria_types[${i}][criteria_type_id]`, it.id);
								data.append(`criteria_types[${i}][description]`, dataInput?.measure_criteria_type?.description || '');
								data.append(`criteria_types[${i}][point]`, dataInput?.measure_criteria_type?.point || 0);
							});

							try {
								const getData = await Axios.put(`/measures/${getRes.id}`, data, {
									headers: {
										'Content-Type': 'multipart/form-data',
									},
								});
								if (getData.status === 200) {
									toast.success(`Measure ${values.measurename} has been updated successfully`);
									navigate(ADMIN_ROUTES.INTRODUCE);
								}
							} catch (e: any) {
								const errorType = e.response?.data?.error?.errors;
								errorType.forEach((element: any) => {
									if (element.message.indexOf('name') !== -1) {
										const alert = element.message.replace('name', 'Measure name');
										toast.error(alert);
									} else if (element.message.indexOf('category_id') !== -1) {
										const alert = element.message.replace('category_id', 'Functionality');
										toast.error(alert);
									} else {
										toast.error(element.message);
									}
								});
							}
						};
						const clearValues = (name: string, id: number, application: any) => {
							if (name === 'application') {
								if (application?.id) {
									setApplicationDeletedIds(prev => {
										prev.push(application.id);
										return prev;
									});
								}
								setFieldValue(`application[${id}]`, null);
							}
						};

						const onChangeRating = (items: any, opt: any, index: number) => {
							setFilters(items);
						};

						const onPdfChange = (pdf?: File) => {
							setFieldValue(`pdfFile`, pdf);
						};

						return (
							<Form>
								<Container
									maxWidth="xl"
									sx={{
										'& a': { textDecoration: 'none' },
										px: { xs: '0 !important', md: '16px !important' },
									}}
								>
									<Box display="flex" alignItems="center" width="100%" flexWrap="wrap" gap={2}>
										<Box display="flex" alignItems="center" flex={1} flexShrink={0} ml={-3}>
											<Link to="/admin/introduce">
												<Button>
													<img src={icon_arrowLeft} alt="icon_arrowLeft" />
												</Button>
											</Link>
											<Typography ml={2} variant="h5" fontWeight="bold" whiteSpace="nowrap">
												Edit measure
											</Typography>
										</Box>
										<Box display="flex">
											<Link to="/admin/introduce">
												<Button
													variant="outlined"
													sx={{
														textDecoration: 'none!important',
														background: 'white',
														color: 'black',
														border: '1px solid #D0D5DD',
													}}
												>
													Cancel
												</Button>
											</Link>
											<Button onClick={() => postData()} sx={{ ml: 3 }} variant="contained">
												Save
											</Button>
										</Box>
									</Box>
								</Container>
								<Divider sx={{ my: 3 }} />
								<Container maxWidth="xl" sx={{ px: { xs: '0 !important', md: '16px !important' } }}>
									<Grid container justifyContent="space-between">
										<Grid md={3} xs={12} item>
											<Typography sx={{ mb: 2 }} fontWeight="600">
												Photo
											</Typography>
										</Grid>
										<Grid md={8} xs={12} item>
											<Container sx={{ mb: 4 }}>
												<Typography fontWeight="600">Photo of measure</Typography>
												<Typography sx={{ mb: 2 }}>Upload up to three (6) photos</Typography>
												<Box sx={{ display: 'flex' }}>
													<Box sx={{ width: '30%', marginRight: '10px' }}>
														<Button sx={buttonStyling} onClick={() => setModalOpenSchematic(0)}>
															<img src={icon_uploadPlus} alt="icon upload" />
														</Button>
														{values.photoSchematic &&
															values.photoSchematic.length >= 1 &&
															(values?.photoSchematic[0]?.attachment_image_url || values?.photoSchematic[0]?.file) && (
																<img
																	style={{ width: '100px', height: '60px' }}
																	className="image-contain"
																	src={
																		values?.photoSchematic[0]?.file
																			? values.photoSchematic[0].file?.preview
																			: values?.photoSchematic[0]?.attachment_image_url
																	}
																/>
															)}
													</Box>
													{[1, 2, 3, 4, 5].map(
														(it: any, id: number) =>
															it <= values?.photoSchematic?.length && (
																<Box key={id} sx={{ width: '30%', marginRight: '10px' }}>
																	<Button sx={buttonStyling} onClick={() => setModalOpenSchematic(it)}>
																		<img src={icon_uploadPlus} alt="icon upload" />
																	</Button>
																	{(values?.photoSchematic[it]?.attachment_image_url ||
																		values?.photoSchematic[it]?.file) && (
																		<img
																			style={{ width: '100px', height: '60px' }}
																			className="image-contain"
																			src={
																				values?.photoSchematic[it]?.file
																					? values?.photoSchematic[it].file?.preview
																					: values?.photoSchematic[it]?.attachment_image_url
																			}
																		/>
																	)}
																</Box>
															)
													)}
												</Box>
											</Container>

											<Container sx={{ mb: 4 }}>
												<Typography fontWeight="600">Location of Potential Application</Typography>
												<Typography sx={{ mb: 2 }}>Upload Potential Application of measure</Typography>
												<Box sx={{ display: 'flex' }}>
													<Box sx={{ width: '30%', marginRight: '10px' }}>
														<Button sx={buttonStyling} onClick={() => setModalLocation(true)}>
															<img src={icon_uploadPlus} alt="icon upload" />
														</Button>
														{(values?.location?.image || values?.location?.file?.preview) && (
															<img
																style={{ width: '100px', height: '60px' }}
																className="image-contain"
																src={
																	values?.location?.file?.preview
																		? values.location?.file?.preview
																		: values?.location?.location_img_url
																}
															/>
														)}
													</Box>
												</Box>
											</Container>
										</Grid>
									</Grid>
								</Container>
								<Divider sx={{ my: 3 }} />

								<Container maxWidth="xl" sx={{ px: { xs: '0 !important', md: '16px !important' } }}>
									<Grid container justifyContent="space-between">
										<Grid md={3} xs={12} item>
											<Typography sx={{ mb: 2 }} fontWeight="600">
												General Information
											</Typography>
										</Grid>
										<Grid md={8} xs={12} item>
											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Measure name *
												</Typography>
												<Fieldrow name="measurename">
													<FieldFormik
														type="text"
														placeholder="ABC Waters design features, etc.."
														name="measurename"
														readonly={false}
													/>
												</Fieldrow>
											</Container>
											{getData &&
												getData !== undefined &&
												getData
													?.filter((e: any) => e.slug === 'functionality')
													?.map((options: any, idx: number) => (
														<Container maxWidth="md" sx={{ mb: 4 }}>
															<Typography sx={{ mb: 2 }} fontWeight="600">
																Functionality *
															</Typography>
															{/* <Select name="Functionality"> */}
															<Field
																// onChange={(e: any) => getFunctionality_tags(e.currentTarget.value, setFieldValue)}
																style={{
																	width: '100%',
																	padding: '10px 15px',
																	border: '1px solid #D0D5DD',
																	borderRadius: '8px',
																}}
																as="select"
																name="Functionality"
																value={values.Functionality}
															>
																<option disabled value="">
																	Make a choice
																</option>
																{options &&
																	options !== undefined &&
																	options.tags?.map((u: any, idx: number) => (
																		<option value={u.id} key={u.name}>
																			{u.name}
																		</option>
																	))}
															</Field>
														</Container>
													))}
											<FieldArray
												name="Typology"
												render={arrayHelpers => (
													<Box>
														{getData &&
															getData !== undefined &&
															getData
																.filter((e: any) => e.slug !== 'land_use_profiles' && e.slug !== 'functionality')
																.map((option: any, index: number) => (
																	<Container key={index} maxWidth="md" sx={{ mb: 4 }}>
																		<Typography sx={{ mb: 2 }} fontWeight="600">
																			{option.name}
																		</Typography>
																		<Select name={`Typology[${index}]`}>
																			<option value="" selected disabled>
																				Make a choice
																			</option>
																			{option &&
																				option !== undefined &&
																				option.tags.map((u: any, idx: number) => (
																					<option value={u.id} key={u.name}>
																						{u.name}
																					</option>
																				))}
																		</Select>
																	</Container>
																))}
													</Box>
												)}
											/>
											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Land Use Profiles
												</Typography>
												<MultiSelect
													options={resUseProfiles_tags && resUseProfiles_tags}
													selectedValues={values.useProfiles}
													name="useProfiles"
													onBlur={() => !touched.useProfiles && setTouched({ ...touched, useProfiles: true })}
												/>
											</Container>
											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Description & Function
												</Typography>
												<Field name="description">
													{({ field, meta }: any) => (
														<TextEditor
															value={values.description}
															onchange={field.onChange(field.name)}
															pdf
															onPdfChange={onPdfChange}
															maxLength={1000}
														/>
													)}
												</Field>
											</Container>

											{/* /////////////////////// */}
										</Grid>
									</Grid>
								</Container>
								<Divider sx={{ my: 3 }} />

								<Container maxWidth="xl" sx={{ px: { xs: '0 !important', md: '16px !important' } }}>
									<Grid container justifyContent="space-between">
										<Grid md={3} xs={12} item>
											<Typography sx={{ mb: 2 }} fontWeight="600">
												Scoring
											</Typography>
										</Grid>
										<Grid md={8} xs={12} item>
											{getCriteria &&
												getCriteria.map((opt: any, index: number) => (
													<Container key={index} maxWidth="md">
														{getRes?.criteria_types
															?.concat(
																getCriteria.filter((x: any) =>
																	getRes?.criteria_types?.every((y: any) => x.id !== y.id)
																) || []
															)
															?.map((val: any, idd: any) => {
																const activeCriterias = opt?.criterias?.filter((it: any) => it.is_scoring_guide);
																return (
																	val?.id === opt?.id && (
																		<Container
																			maxWidth="md"
																			sx={{
																				mb: 4,
																				display: 'flex',
																				alignItems: 'flex-start',
																				flexDirection: 'column',
																				px: '0 !important',
																				position: 'relative',
																				'.character-left': {
																					pl: 3.5,
																				},
																			}}
																			hidden={!opt.is_scoring_guide}
																		>
																			<Box px={2} py={1.25} bgcolor="grey.100" borderRadius={'8px'} width={'100%'}>
																				<Typography fontWeight="600">{opt.name}</Typography>
																			</Box>
																			<RatingEditAdmin
																				onChange={onChangeRating}
																				value={val.measure_criteria_type?.point}
																				name={`scoringParentTechnicalPerformance[${idd}]`}
																				ids={opt.id}
																				title=""
																				readonly={false}
																				keyName="measure_criteria_type"
																			/>
																			<Textarea
																				value={
																					values?.scoringParentTechnicalPerformance &&
																					values?.scoringParentTechnicalPerformance?.filter(
																						(e: any) => e?.id === opt?.id
																					)?.[0]?.measure_criteria_type?.description
																				}
																				name={`scoringParentTechnicalPerformance[${idd}].measure_criteria_type.description]`}
																				placeholder="Enter a description..."
																				maxLength={500}
																				onChange={() =>
																					setFieldValue(`scoringParentTechnicalPerformance[${idd}].id`, opt.id)
																				}
																			/>
																			{!!activeCriterias?.length && (
																				<Box
																					position="absolute"
																					width="1px"
																					bgcolor="#D0D5DD"
																					top="calc(100% - 36px)"
																					left="16px"
																					height={`calc(77px + ${(activeCriterias.length - 1) * 235.52}px)`}
																				/>
																			)}
																		</Container>
																	)
																);
															})}
														{!!opt &&
															opt?.criterias?.map((options: any, idx: number) => (
																<Fragment key={options.id}>
																	{getRes?.criterias.map((valueRes: any, idxkey: number) => (
																		<Fragment key={idxkey}>
																			{valueRes.id === options.id && (
																				<>
																					<Container
																						key={idx}
																						maxWidth="md"
																						sx={{
																							pb: 4,
																							display: 'flex',
																							alignItems: 'flex-start',
																							flexDirection: 'column',
																							pl: '40px !important',
																							pr: '0 !important',
																						}}
																						hidden={!options.is_scoring_guide || !opt.is_scoring_guide}
																					>
																						<Box position="relative">
																							<Typography fontWeight="500" variant="body2" mb={-0.5}>
																								{options.principle}
																							</Typography>
																							<Box
																								position="absolute"
																								height="1px"
																								bgcolor="#D0D5DD"
																								width={'16px'}
																								top="50%"
																								left="-23px"
																							/>
																						</Box>
																						<RatingEditAdmin
																							key={idxkey}
																							onChange={onChangeRating}
																							value={valueRes.measure_criteria?.point}
																							name={`scoringTechnicalPerformance[${idxkey}]`}
																							ids={options.id}
																							title=""
																							readonly={false}
																						/>
																						<Textarea
																							value={
																								values?.scoringTechnicalPerformance &&
																								values?.scoringTechnicalPerformance?.filter(
																									(e: any) => e?.id === valueRes?.id
																								)[0]?.measure_criteria?.description
																							}
																							name={`scoringTechnicalPerformance[${idxkey}].measure_criteria.description]`}
																							placeholder="Enter a description..."
																							maxLength={500}
																						/>
																					</Container>
																				</>
																			)}
																		</Fragment>
																	))}
																	{!getRes?.criterias?.some((e: any) => e.id === options?.id) && (
																		<Container
																			key={idx}
																			maxWidth="md"
																			sx={{
																				pb: 4,
																				display: 'flex',
																				alignItems: 'flex-start',
																				flexDirection: 'column',
																				pl: '40px !important',
																				pr: '0 !important',
																			}}
																			hidden={!options.is_scoring_guide || !opt.is_scoring_guide}
																		>
																			<Box position="relative">
																				<Typography fontWeight="500" variant="body2" mb={-0.5}>
																					{options.principle}
																				</Typography>
																				<Box
																					position="absolute"
																					height="1px"
																					bgcolor="#D0D5DD"
																					width={'16px'}
																					top="50%"
																					left="-23px"
																				/>
																			</Box>
																			<RatingCustomAdmin
																				onChange={onChangeRating}
																				value={0}
																				name={`scoringTechnicalPerformance.${[idx + getRes?.criterias?.length]}`}
																				ids={options.id}
																				readonly={false}
																				title=""
																			/>
																			<Textarea
																				value={values?.scoringTechnicalPerformance[options.id]?.description}
																				name={`scoringTechnicalPerformance.${[
																					idx + getRes?.criterias?.length,
																				]}.description]`}
																				placeholder="Enter a description..."
																				maxLength={500}
																				onChange={() =>
																					setFieldValue(
																						`scoringTechnicalPerformance[${idx + getRes?.criterias?.length}].id`,
																						options.id
																					)
																				}
																			/>
																		</Container>
																	)}
																</Fragment>
															))}
													</Container>
												))}
										</Grid>
									</Grid>
								</Container>
								<Divider sx={{ my: 3 }} />

								<Container maxWidth="xl" sx={{ px: { xs: '0 !important', md: '16px !important' } }}>
									<Grid container justifyContent="space-between">
										<Grid md={3} xs={12} item>
											<Typography sx={{ mb: 2 }} fontWeight="600">
												Application example
											</Typography>
										</Grid>
										<Grid md={8} xs={12} item>
											{['first', 'second', 'third'].map((items: any, id: number) => {
												const application = values?.application?.[id];
												return (
													<Container
														key={id}
														maxWidth="md"
														sx={{
															mb: 4,
															background: '#FCFCFD',
															border: '1px solid #E5E7EB',
															borderRadius: '8px',
															padding: '0 !important',
														}}
													>
														<Button
															sx={{
																textTransform: 'none',
																display: 'flex',
																position: 'relative',
																justifyContent: 'flex-start',
																px: 2,
																py: 1.5,
																flexWrap: { xs: 'wrap', lg: 'unset' },
																gap: 1,
																minHeight: '64px',
															}}
															onClick={() => {
																setModalOpen(true);
																setApplicationId(id);
															}}
															fullWidth
														>
															{application?.image?.preview || application?.application_image_url ? (
																<Box
																	width="22%"
																	minWidth="130px"
																	mr={1}
																	pt={application?.title || application?.description ? 1 : 0}
																	flexShrink={0}
																	alignSelf="flex-start"
																>
																	<img
																		src={application?.image?.preview || application?.application_image_url}
																		className="image-contain"
																	/>
																</Box>
															) : (
																<Box mr={1}>
																	<img src={icon_addMore} alt="add more" />
																</Box>
															)}
															<Box display="flex" alignItems="center">
																{application?.title || application?.description ? (
																	<Box mr={4} textAlign="left">
																		{application?.title && (
																			<Typography mb={1} sx={{ color: '#344054' }} fontWeight="600">
																				{application?.title}
																			</Typography>
																		)}
																		<Typography sx={{ color: '#475467' }}>{application?.description}</Typography>
																	</Box>
																) : (
																	<Typography sx={{ color: '#384250' }}>Add application example</Typography>
																)}
															</Box>
															{application && (
																<Box position="absolute" top={4} right={4}>
																	<Button
																		onClick={e => {
																			e.stopPropagation();
																			clearValues('application', id, application);
																		}}
																		sx={{ minWidth: 'fit-content', height: 'fit-content' }}
																	>
																		<img src={icon_remove} alt="remove logo" />
																	</Button>
																</Box>
															)}
														</Button>
													</Container>
												);
											})}
										</Grid>
									</Grid>
								</Container>
								<Divider sx={{ my: 3 }} />
								<Container sx={{ mb: 20, px: { xs: '0 !important', md: '16px !important' } }} maxWidth="xl">
									<Grid container justifyContent="space-between">
										<Grid md={3} xs={12} item>
											<Typography sx={{ mb: 2 }} fontWeight="600">
												Potential combination with other measures
											</Typography>
										</Grid>
										<Grid md={8} xs={12} item>
											{functionality_tags && (
												<MultiSelect
													options={functionality_tags}
													selectedValues={values.potential}
													placeholders="Choose measure"
													name="potential"
													onBlur={() => !touched.potential && setTouched({ ...touched, potential: true })}
												/>
											)}
										</Grid>
									</Grid>
								</Container>
								{/* ////////////// Modal ///////////// */}
								<ModalAdminCustom
									openModal={typeof modalOpenSchematic === 'number'}
									id={modalOpenSchematic}
									name="photoSchematic"
									handleClose={() => setModalOpenSchematic(undefined)}
								/>
								<ModalLocationEdit
									openModal={modalOpenLocation}
									name="location"
									handleClose={() => setModalLocation(false)}
									setTouched={() => !touched.location && setTouched({ ...touched, location: true })}
								/>
								<ModalAdmin isOpen={modalOpen} handleClose={() => setModalOpen(false)}>
									<Container maxWidth="xl" sx={{ mb: 4 }}>
										<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
											<Typography
												fontSize="24px"
												sx={{
													lineHeight: {
														md: '24px',
														xs: '18px',
													},
													my: '30px',
													fontWeight: '600',
												}}
											>
												Add application example
											</Typography>
											<Button sx={{ fontSize: '20px', color: 'black' }} onClick={() => setModalOpen(false)}>
												X
											</Button>
										</Box>
										<Box>
											<Typography fontWeight="600">Photo of application</Typography>
											<Typography sx={{ mb: 2 }}>
												Please resize the picture to exactly 1920x1080px before uploading it
											</Typography>
											<Box
												width="100%"
												sx={{
													justifyContent: 'center',
													display: 'flex',
													flexDirection: 'column',
												}}
											>
												<FileInput
													name={`application[${applicationId}].image`}
													label=""
													maxsize="SVG, PNG, JPG or GIF (max. 1920x1080px)"
													setTouched={() => !touched.application && setTouched({ ...touched, application: true })}
													ThumbTrigger
													previewImg={
														values?.application[applicationId]?.image?.preview ||
														values?.application[applicationId]?.application_image_url
													}
												/>
											</Box>
											<Typography sx={{ my: 2 }} fontWeight="600">
												Application name
											</Typography>
											<FieldFormik
												type="text"
												placeholder="ABC Waters design features, etc.."
												name={`application[${applicationId}.title]`}
												readonly={false}
												maxLength={127}
											/>
											<Typography sx={{ my: 2 }} fontWeight="600">
												Description
											</Typography>
											<Textarea
												name={`application[${applicationId}.description]`}
												placeholder="Enter a description..."
												maxLength={500}
												value={values.application[applicationId]?.description}
											/>
										</Box>
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'flex-end',
												mt: '10px',
											}}
										>
											<Button
												sx={{ mr: '10px' }}
												onClick={() => {
													clearValues('application', applicationId, values.application?.[applicationId]);
													setModalOpen(false);
												}}
												variant="contained"
											>
												Delete
											</Button>
											<Button onClick={() => setModalOpen(false)} variant="contained">
												Apply
											</Button>
										</Box>
									</Container>
								</ModalAdmin>
							</Form>
						);
					}}
				</Formik>
			</section>
		</AdminLayout>
	);
};
export default AdminEditMeasure;
