import HorizontalRuleRoundedIcon from '@mui/icons-material/HorizontalRuleRounded';
import Rating from '@mui/material/Rating';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import * as React from 'react';

interface ratingProps {
	value: number;
	readonly?: boolean;
	align?: 'left' | 'center';
}

const StyledRating = styled(Rating)(props => ({
	color: (function () {
		switch (Number(props.defaultValue)) {
			case 1:
				return '#FF5372';
			case 2:
				return '#FF8F28';
			case 3:
				return '#FDE600';
			case 4:
				return '#94C259';
			case 5:
				return '#179C01';
			default:
				return '';
		}
	})(),
	'&>*': {
		color: 'inherit',
	},
	'.MuiRating-icon': {
		color: Number(props.defaultValue) === 0 ? 'rgba(0, 0, 0, 0.26)' : 'inherit',
	},
}));

const RatingCustom: React.FC<ratingProps> = ({ value, readonly = true, align = 'left' }) => {
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				cursor: readonly ? 'default' : 'pointer',
				justifyContent: align === 'center' ? 'center' : 'flex-start',
				minWidth: '50px',
			}}
		>
			<Box flexShrink={0} display="flex" alignItems="center">
				<StyledRating
					name="customized-color"
					readOnly={readonly}
					defaultValue={value}
					icon={
						<HorizontalRuleRoundedIcon
							fontSize="small"
							sx={{
								transform: 'scale(9, 4) rotate(90deg)',
								width: '10px',
							}}
						/>
					}
					emptyIcon={
						<HorizontalRuleRoundedIcon
							fontSize="small"
							sx={{ transform: 'scale(9, 4) rotate(90deg)', width: '10px' }}
						/>
					}
					max={value || 1}
				/>
			</Box>
		</Box>
	);
};

export default RatingCustom;
