import { Box, Button, Container, Typography } from '@mui/material';
import { FieldFormik, FileInput, Textarea } from 'components/atoms';
import { ModalAdmin } from 'components/organisms';
import { useFormikContext } from 'formik';
import React from 'react';

interface Props {
	handleClose: () => void;
	setTouched?: () => void;
	name: string;
	openModal?: boolean;
	id?: number;
}

const ModalLocationCreate: React.FC<Props> = props => {
	const { handleClose, name, openModal, id = null } = props;
	const { setFieldValue, values } = useFormikContext<any>();

	const clearValues = () => {
		setFieldValue('location', {});
		handleClose();
	};

	const saveValues = () => {
		handleClose();
	};

	return (
		<ModalAdmin isOpen={openModal} handleClose={handleClose}>
			<Container maxWidth="xl" sx={{ mb: 4 }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Typography
						fontSize="24px"
						sx={{
							lineHeight: {
								md: '24px',
								xs: '18px',
							},
							my: '30px',
							fontWeight: '600',
						}}
					>
						Edit photo
					</Typography>
					<Button sx={{ fontSize: '20px', color: 'black' }} onClick={handleClose}>
						X
					</Button>
				</Box>
				<Box>
					<Typography sx={{ mt: 2 }} fontWeight="600">
						Photo of application
					</Typography>
					<Typography sx={{ mb: 2 }}>Please resize the picture to exactly 1920x1080px before uploading it</Typography>
					<Box sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
						<FileInput
							name={`${name}.file`}
							label=""
							maxsize="SVG, PNG, JPG or GIF (max. 1920x1080px)"
							ThumbTrigger
							previewImg={values?.location?.file?.preview}
						/>
					</Box>
					<Typography sx={{ my: 2 }} fontWeight="600">
						Application name
					</Typography>
					<FieldFormik
						type="text"
						placeholder="ABC Waters design features, etc.."
						name={`${name}[title]`}
						readonly={false}
						maxLength={250}
					/>
					<Typography sx={{ my: 2 }} fontWeight="600">
						Description
					</Typography>
					<Textarea
						name="location[description]"
						placeholder="Enter a description..."
						maxLength={500}
						value={values?.location?.description}
					/>
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '10px' }}>
					<Button sx={{ mr: '10px' }} onClick={clearValues} variant="contained">
						Delete
					</Button>
					<Button onClick={handleClose} variant="contained">
						Apply
					</Button>
				</Box>
			</Container>
		</ModalAdmin>
	);
};

export default ModalLocationCreate;
