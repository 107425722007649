import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { ADMIN_ROUTES } from 'routers/consts';

export interface Criteria {
	id: string;
	name: string;
	scoring_guide: { [key: string]: string } | string[];
}

interface CriteriaListProps {
	data: Criteria[];
	onUpdate: (arg?: Criteria) => void;
	onDelete: (arg?: Criteria) => void;
}

const CriteriaList = (props: CriteriaListProps) => {
	const { data, onUpdate, onDelete } = props;

	return (
		<>
			{data?.map((criteria: Criteria) => {
				return (
					<Box
						key={criteria.id}
						sx={{
							maxWidth: 856,
							marginBottom: '16px',
							border: '1px solid #EAECF0',
							borderRadius: '12px',
							boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.06)',
						}}
					>
						<Box
							display="flex"
							justifyContent="space-between"
							sx={{ padding: '24px', borderBottom: '1px solid #EAECF0' }}
						>
							<Typography sx={{ fontSize: 18, fontWeight: 600 }}>{criteria.name}</Typography>
							<Box>
								<IconButton onClick={() => onUpdate(criteria)}>
									<FiEdit2 fontSize="medium" />
								</IconButton>
								<IconButton onClick={() => onDelete(criteria)}>
									<FiTrash2 fontSize="medium" />
								</IconButton>
							</Box>
						</Box>
						<Box sx={{ padding: '16px 24px', a: { textDecoration: 'none' } }}>
							<Link to={`${ADMIN_ROUTES.CRITERIA.replace(':id', criteria.id)}`}>
								<Typography sx={{ fontSize: 14, fontWeight: 600 }}>View Criteria</Typography>
							</Link>
						</Box>
					</Box>
				);
			})}
		</>
	);
};

export default CriteriaList;
