import { Box } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill, { Quill, UnprivilegedEditor } from 'react-quill';

import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';

export interface ITextEditorProps {
	value?: string;
	readonly?: boolean;
	toolbar?: any;
	theme?: 'snow' | 'bubble';
	onchange?: any;
	maxLength?: number;
	pdf?: boolean;
	onPdfChange?: (file?: File) => void;
}

const defaultToolbarOptions = [
	['bold', 'italic', 'underline', 'strike'], // toggled buttons
	['blockquote', 'code-block'],
	[{ header: 1 }, { header: 2 }], // custom button values
	[{ list: 'ordered' }, { list: 'bullet' }],
	[{ script: 'sub' }, { script: 'super' }], // superscript/subscript
	[{ indent: '-1' }, { indent: '+1' }], // outdent/indent
	[{ direction: 'rtl' }], // text direction

	[{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
	[{ header: [1, 2, 3, 4, 5, 6, false] }],

	[{ color: [] }, { background: [] }], // dropdown with defaults from theme
	[{ font: [] }],
	[{ align: [] }],

	['clean'], // remove formatting button
];

const Inline = Quill.import('blots/inline');

class Pdf extends Inline {
	static blotName = 'pdf';
	static tagName = 'pdf';

	static create(value: any) {
		const node = super.create() as Element;
		node.setAttribute('pdf', 'true');
		node.setAttribute('href', 'about:blank');
		node.setAttribute('rel', 'noopener noreferrer');
		node.setAttribute('target', '_blank');
		return node;
	}
}

Quill.register({
	'formats/pdf': Pdf,
});

const TextEditor: React.FC<ITextEditorProps> = props => {
	const { value, readonly = false, theme = 'snow', onchange, maxLength, pdf, onPdfChange = (v: any) => null } = props;

	const container = useRef<HTMLElement | null>(null);
	const quill = useRef<ReactQuill>(null);
	const [validatePdfIcon, setValidatePdfIcon] = useState({});

	const newModules = useMemo(() => {
		const newToolbar = JSON.parse(JSON.stringify(defaultToolbarOptions));
		if (pdf) {
			newToolbar[0].push('link');
			newToolbar[0].push('pdf-link');
		}
		return {
			toolbar: newToolbar,
		};
	}, []);

	const setPdfTooltipPosition = (reference: ReturnType<UnprivilegedEditor['getBounds']>) => {
		const root = container.current?.querySelector('.ql-pdf-tooltip') as HTMLDivElement;
		if (!root) return;
		const left = reference.left + reference.width / 2 - root.offsetWidth / 2;
		// root.scrollTop should be 0 if scrollContainer !== root
		const top = reference.bottom + (quill.current?.editor?.root?.scrollTop || 0);
		root.style.left = `${left}px`;
		root.style.top = `${top}px`;
		root.classList.remove('ql-flip');
		const containerBounds = document.body.getBoundingClientRect();
		const rootBounds = root.getBoundingClientRect();
		let shift = 0;
		if (rootBounds.right > containerBounds.right) {
			shift = containerBounds.right - rootBounds.right;
			root.style.left = `${left + shift}px`;
		}
		if (rootBounds.left < containerBounds.left) {
			shift = containerBounds.left - rootBounds.left;
			root.style.left = `${left + shift}px`;
		}
		if (rootBounds.bottom > containerBounds.bottom) {
			const height = rootBounds.bottom - rootBounds.top;
			const verticalShift = reference.bottom - reference.top + height;
			root.style.top = `${top - verticalShift}px`;
			root.classList.add('ql-flip');
		}
		return shift;
	};

	useEffect(() => {
		if (!pdf) return;
		const pdfIcon = container.current?.querySelector('.ql-pdf-link');
		if (pdfIcon) {
			pdfIcon.innerHTML = `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="PictureAsPdfIcon"><path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z"></path></svg>`;
		}
		quill.current?.editor?.addContainer('ql-pdf-tooltip');
		const pdfTooltip = container.current?.querySelector('.ql-pdf-tooltip');
		if (pdfTooltip) {
			pdfTooltip.classList.add('ql-hidden');
			pdfTooltip.innerHTML = `<a class="ql-action">Edit</a><a class="ql-remove">Remove</a>`;
		}

		const handleUpdatePdf = (e: any) => {
			if (e.target.getAttribute('class') === 'ql-action') {
				const input = document.createElement('input');
				input.type = 'file';
				input.accept = 'application/pdf';
				input.onchange = (e: any) => {
					if (e.target.files[0]) {
						onPdfChange(e.target.files[0]);
						input.remove();
					}
				};
				input.click();
			} else if (e.target.getAttribute('class') === 'ql-remove') {
				container.current?.querySelectorAll('[pdf=true]')?.forEach(e => {
					e.replaceWith(document.createTextNode(e.textContent || ''));
				});
				onPdfChange();
				setValidatePdfIcon({});
			}
			pdfTooltip?.classList?.add('ql-hidden');
		};

		const handleSelectionChange = (range: any, oldRange: any, source: string) => {
			if (!range) {
				pdfTooltip?.classList.add('ql-hidden');
				return;
			}
			if (range.length === 0 && source === 'user') {
				const [pdf, offset] = (quill.current as any)?.editor?.scroll?.descendant(Pdf, range.index);
				if (pdf) {
					pdfTooltip?.classList.remove('ql-hidden');
					const bounds = quill.current?.editor?.getBounds(range.index - offset, pdf.length());
					if (bounds) {
						setPdfTooltipPosition(bounds);
					}
				} else {
					pdfTooltip?.classList.add('ql-hidden');
				}
			}
		};

		const handleUploadPdf = () => {
			const range = quill.current?.editor?.getSelection();
			if (!range?.length) return;
			const input = document.createElement('input');
			input.type = 'file';
			input.accept = 'application/pdf';
			input.onchange = (e: any) => {
				if (e.target.files[0]) {
					const range = quill.current?.editor?.getSelection();
					if (range) {
						quill.current?.editor?.formatText(range, 'pdf', 'about:blank', 'user');
					}
					onPdfChange && onPdfChange(e.target.files[0]);
					setValidatePdfIcon({});
					input.remove();
				}
			};
			input.click();
		};

		quill.current?.editor?.on('selection-change', handleSelectionChange);
		quill.current?.editor?.on('text-change', delta => {
			const range = quill.current?.editor?.getSelection();
			const [pdf] = (quill.current as any)?.editor?.scroll?.descendant(Pdf, range?.index);
			if (!pdf && delta?.ops?.find(it => it?.attributes?.pdf) && range) {
				quill.current?.editor?.removeFormat(range.index > 0 ? range.index - 1 : range.index, 1);
			}
			setValidatePdfIcon({});
		});
		pdfIcon?.addEventListener('click', handleUploadPdf);
		pdfTooltip?.addEventListener('click', handleUpdatePdf);

		return () => {
			quill.current?.editor?.off('selection-change', handleSelectionChange);
			pdfIcon?.removeEventListener('click', handleUploadPdf);
			pdfTooltip?.removeEventListener('click', handleUpdatePdf);
		};
	}, []);

	useEffect(() => {
		if (!pdf || !container.current) return;
		const pdfIcon = container.current?.querySelector('.ql-pdf-link') as any;
		const pdfElement = container.current?.querySelector('[pdf=true]');
		if (pdfElement && pdfIcon) {
			pdfIcon.style.pointerEvents = 'none';
			pdfIcon.querySelector('path').style.fill = '#c6c3c3';
		} else if (pdfIcon) {
			pdfIcon.style.pointerEvents = '';
			pdfIcon.querySelector('path').style.fill = '';
		}
	}, [value, validatePdfIcon]);

	const handleChange = (...params: any) => {
		const editor = quill.current?.getEditor();
		if (editor && maxLength) {
			if (editor.getLength() > maxLength) {
				editor.deleteText(maxLength - 1, editor.getLength());
			}
			params[0] = quill.current?.unprivilegedEditor?.getHTML();
		}
		onchange(...params);
	};

	return (
		<Box ref={container}>
			<ReactQuill
				className=""
				ref={quill}
				theme={theme}
				value={value}
				modules={newModules}
				readOnly={readonly}
				preserveWhitespace
				onChange={handleChange}
			/>

			{maxLength && (
				<Box mt={1} color="#475467" fontSize="14px">{`${
					quill.current?.unprivilegedEditor?.getLength() || 0
				}/${maxLength}`}</Box>
			)}
		</Box>
	);
};

export default TextEditor;
