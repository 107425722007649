import { array, object, string } from 'yup';

export const principleSchema = object({
	principle: string().trim().required('Criteria name is required'),
	definition: string(),
	scoring_guide: array().when(['is_scoring_guide'], {
		is: true,
		then: schema => schema.of(string().trim().required('Score guide is required')),
	}),
});

export const defaultInitialPrincipleValues = {
	principle: '',
	definition: '',
	scoring_guide: ['', '', '', '', ''],
	is_scoring_guide: true,
};
