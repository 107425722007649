import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { IconDownloadToolkit, IconUserPortal } from 'Icons';
import { DownloadToolkit } from 'components/organisms';
import {
	AdminCreateMeasure,
	AdminEditMeasure,
	CategoriesPage,
	CheckEmailPage,
	CreatePasswordPage,
	ForgotPasswordPage,
	GetStartedPage,
	HomePage,
	LoginPage,
	MeasureItemPage,
	ProfilePage,
	ResetPasswordPage,
	ToolkitPage,
} from 'components/pages';
import AdminCriteria from 'components/pages/AdminCriteria';
import AdminCriteriaSetting from 'components/pages/AdminCriteriaSetting';
import AdminFilter from 'components/pages/AdminFilter';
import AdminHompageCMS from 'components/pages/AdminHompageCMS';
import AdminMeasurePage from 'components/pages/AdminIntroduce';
import AdminIntroduction from 'components/pages/AdminIntroduction';
import AdminUsersPage from 'components/pages/AdminUsers';
import { ADMIN_ROUTES, ROUTES } from './consts';

export const auth = [
	{
		path: ROUTES.HOME,
		element: HomePage,
	},
	{
		path: ROUTES.TOOLKIT,
		element: ToolkitPage,
	},
	{
		path: ROUTES.CATEGORIES,
		element: CategoriesPage,
	},
	{
		path: ROUTES.PROFILE,
		element: ProfilePage,
		onlineMode: true,
	},
	{
		path: ROUTES.MEASUREITEM,
		element: MeasureItemPage,
	},
];

export const unAuth = [
	{
		path: ROUTES.GET_STARTED,
		element: GetStartedPage,
	},
	{
		path: ROUTES.CREATE_PASSWORD,
		element: CreatePasswordPage,
	},
	{
		path: ROUTES.LOGIN,
		element: LoginPage,
	},
	{
		path: ROUTES.CHECK_MAIL,
		element: CheckEmailPage,
	},
	{
		path: ROUTES.FORGOT_PASSWORD,
		element: ForgotPasswordPage,
	},
	{
		path: ROUTES.RESET_PASSWORD,
		element: ResetPasswordPage,
	},
];

export const admin = [
	{
		name: 'Users',
		path: ADMIN_ROUTES.USERS,
		element: AdminUsersPage,
		icon: GroupIcon,
	},
	{
		name: 'Introduction',
		path: ADMIN_ROUTES.INTRODUCTION,
		element: AdminIntroduction,
		icon: LayersOutlinedIcon,
	},
	{
		name: ' Measure',
		path: ADMIN_ROUTES.INTRODUCE,
		element: AdminMeasurePage,
		icon: DashboardIcon,
	},
	{
		name: 'Homepage CMS',
		path: ADMIN_ROUTES.HOMEPAGE_CMS,
		element: AdminHompageCMS,
		icon: HomeIcon,
	},
	{
		name: 'createmeasure',
		path: ADMIN_ROUTES.CREATE,
		element: AdminCreateMeasure,
		icon: DashboardIcon,
		invisible: true,
	},
	{
		name: 'editmeasure',
		path: ADMIN_ROUTES.EDIT,
		element: AdminEditMeasure,
		icon: DashboardIcon,
		invisible: true,
	},
	{
		name: 'Filter',
		path: ADMIN_ROUTES.FILTER,
		element: AdminFilter,
		icon: SettingsIcon,
		invisible: true,
	},
	{
		name: 'Go to User Portal',
		path: ROUTES.HOME,
		element: HomePage,
		icon: IconUserPortal,
		position: 'bottom',
	},
	{
		name: 'Offline Toolkit',
		path: ADMIN_ROUTES.TOOLKIT,
		element: DownloadToolkit,
		icon: IconDownloadToolkit,
		position: 'bottom',
	},
	{
		name: 'Scoring Pillar Setting',
		path: ADMIN_ROUTES.SCORING_CRITERIA_SETTING,
		element: AdminCriteriaSetting,
		icon: SettingsIcon,
		position: 'bottom',
	},
	{
		name: 'Criteria',
		path: ADMIN_ROUTES.CRITERIA,
		element: AdminCriteria,
		icon: SettingsIcon,
		invisible: true,
	},
];
