import { Box, Breadcrumbs, Button, Container, Grid, Typography } from '@mui/material';
import Layout from 'components/organisms/Layout';
import * as React from 'react';
// import Carousel from 'react-material-ui-carousel'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
	Image_3D_1,
	Image_3D_2,
	Image_3D_3,
	Image_Schematic_1,
	Image_Schematic_2,
	Image_Schematic_3,
} from 'assets/images/index';
import { Axios } from 'core/httpServices';
import { NetworkContext, useCompareContext } from 'providers/context';
import { useContext, useEffect, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import { MeasureItemDetail } from '../../organisms/Measure/index';

interface MeasureItemPageProps {}
const ButtonStyling = {
	justifyContent: 'space-between',
	border: '1px solid #E5E7EB',
	borderRadius: '10px',
	px: '16px',
	m: {
		xs: '10px 0 0 0',
		md: '10px 10px 0 10px',
	},
	color: '#6C737F',
	minWidth: { sm: '180px' },
};
const ButtonStylingActive = {
	justifyContent: 'space-between',
	border: '1px solid #1570EF',
	borderRadius: '10px',
	px: '16px',
	m: {
		xs: '10px 0 0 0',
		md: '10px 10px 0 10px',
	},
	background: '#F5FAFF',
	minWidth: { sm: '180px' },
};

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 1,
		paritialVisibilityGutter: 60,
		slidesToSlide: 1,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1,
		paritialVisibilityGutter: 50,
		slidesToSlide: 1,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		paritialVisibilityGutter: 30,
		slidesToSlide: 1,
	},
};

const items = [
	{
		src: [Image_Schematic_1, Image_Schematic_2, Image_Schematic_3],
	},
	{
		src: [Image_3D_1, Image_3D_2, Image_3D_3],
	},
];

const MeasureItemPage: React.FC<MeasureItemPageProps> = ({}) => {
	const { isOnline } = useContext(NetworkContext);
	const [hoverButton3D_diagram_1, setHoverButton3D_diagram_1] = useState<number>(0);
	const [getRes, setGetRes] = useState<any>();
	const [getCriteria, setGetCriteria] = useState<any>();
	const [getschematicImage, setGetschematicImage] = useState<any>();
	const { showCompareTab, handleShowCompareTab } = useCompareContext();
	const getMeasuresDetail = async () => {
		const query = window.location.href;
		const result = query?.substring(query.indexOf('='));
		const id_number = result.replace(result.substring(0, 1), '');

		if (isOnline) {
			try {
				const getData = await Axios.get(
					`/measures/${id_number}?includes=applications,attachments,criterias,tags,location,potentials,criteria_types,description`
				);
				const getCriteria = await Axios.get(`/criteria-types?includes=criterias,criteria_types&sort=created_at`);

				setGetCriteria(getCriteria.data.data);
				setGetRes(getData?.data);
				const GetschematicImage = getData?.data.attachments;
				setGetschematicImage(GetschematicImage);
			} catch {
				console.log('error');
			}
		} else {
			// @ts-ignore
			const getData = measure as any;
			// @ts-ignore
			const getCriteria = criteria_types;
			setGetCriteria(getCriteria);
			const res = getData?.filter((e: any) => id_number.includes(e.id));
			setGetRes(res[0]);
			const GetschematicImage = res[0].attachments;
			setGetschematicImage(GetschematicImage);
		}
	};
	useEffect(() => {
		getMeasuresDetail();
		return () => handleShowCompareTab(false);
	}, []);

	return (
		<Layout>
			<Container maxWidth="xl">
				<Breadcrumbs
					sx={{
						mb: {
							xs: '0px',
							md: '50px',
						},
						padding: {
							xs: '0px',
							md: '0px 2rem',
						},
					}}
					separator={<NavigateNextIcon fontSize="small" />}
					aria-label="breadcrumb"
				>
					<Link
						style={{
							color: '#667085',
							textDecoration: 'none',
							fontFamily: 'Inter',
							fontWeight: '600',
							fontSize: '14px',
							lineHeight: '18px',
						}}
						to={`/home`}
					>
						Home
					</Link>
					<Link
						style={{
							color: '#667085',
							textDecoration: 'none',
							fontFamily: 'Inter',
							fontWeight: '600',
							fontSize: '14px',
							lineHeight: '18px',
						}}
						to={`/categories`}
						state={{ tab: showCompareTab ? 2 : undefined }}
					>
						Adaptation Measures
					</Link>
					<Typography
						sx={{
							fontFamily: 'Inter',
							fontWeight: '600',
							fontSize: '14px',
							lineHeight: '18px',
							color: '#2E90FA',
							fontStyle: 'normal',
						}}
					>
						{getRes && getRes.name}
					</Typography>
				</Breadcrumbs>
				<Grid
					container
					mb={{
						xs: '30px',
						md: '100px',
					}}
					px={{
						xs: '0px',
						md: '2rem',
					}}
					sx={{
						flexDirection: {
							xs: 'column',
							md: 'row',
						},
					}}
				>
					<Grid
						item
						xs={12}
						md={3}
						sm={12}
						display="flex"
						flexDirection="column"
						justifyContent="center"
						// sx={{textAlign: {sm: 'center'}}}
					>
						<Box
							display="flex"
							sx={{
								background: {
									xs: 'none',
									md: '#F9FAFB',
								},
								padding: {
									xs: '0px',
									md: '0px 2rem',
								},
								height: '100%',
								justifyContent: 'space-between',
								flexDirection: 'column',
							}}
						>
							<Box maxWidth="md" sx={{ pt: '50px' }}>
								<Typography
									fontSize="18px"
									color={getRes?.category?.color}
									sx={{
										lineHeight: {
											md: '20px',
											xs: '18px',
										},
										pb: '30px',
									}}
								>
									{getRes && getRes.category?.name}
								</Typography>
								<Typography
									fontSize="36px"
									sx={{
										lineHeight: '40px',
										overflowWrap: 'break-word',
									}}
								>
									{getRes && getRes.name}
								</Typography>
							</Box>
						</Box>
					</Grid>
					<Grid
						item
						xs={12}
						md={9}
						sm={12}
						display="flex"
						flexDirection="column"
						justifyContent="center"
						sx={{ background: '#F2F4F7' }}
					>
						<Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
							<div style={{ height: '600px' }}>
								<div style={{ height: '600px', background: 'white', display: 'flex' }}>
									<img
										key={hoverButton3D_diagram_1}
										style={{
											height: 'auto',
											margin: 'auto',
											width: 'auto',
											maxHeight: '80%',
											maxWidth: '90%',
											objectFit: 'contain',
										}}
										src={
											isOnline
												? getschematicImage?.[hoverButton3D_diagram_1]?.attachment_image_url
												: getschematicImage?.[hoverButton3D_diagram_1]?.image
										}
									/>
									<div
										style={{
											position: 'absolute',
											bottom: '0px',
											width: '100%',
											background: 'white',
											backdropFilter: 'blur(6px)',
										}}
									>
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'center',
												py: '10px',
											}}
										>
											{getschematicImage?.map((img: string, id: number) => (
												<Button
													key={id}
													onClick={() => setHoverButton3D_diagram_1(id)}
													sx={{
														background: hoverButton3D_diagram_1 === id ? '#1570EF' : '#F3F4F6',
														color: hoverButton3D_diagram_1 === id ? 'white' : 'black',
														borderRadius: '30px',
														px: '20px',
														mx: '5px',
														'&:hover': {
															background: '#1570EF',
															color: 'white',
														},
													}}
												>
													{getschematicImage?.[id]?.name}
												</Button>
											))}
										</Box>
									</div>
								</div>
							</div>
						</Box>
					</Grid>
				</Grid>
				<MeasureItemDetail value={getRes} criteriaList={getCriteria} />
			</Container>
		</Layout>
	);
};

export default MeasureItemPage;
