import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
	AccordionDetails,
	AccordionSummary,
	Box,
	IconButton,
	Accordion as MuiAccordion,
	Typography,
} from '@mui/material';
import { AccordionProps } from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import { RatingCustom } from 'components/atoms';
import Image from 'components/atoms/Image';
import { Measure, useGetCriteriaTypes } from 'modules/measure';
import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routers';
import { colors } from 'styles';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
	({ theme }) => ({
		borderBottom: `1px solid ${colors.gray[200]}`,
		padding: '0 14px!important',
		'&:before': {
			display: 'none',
		},
		'&:first-of-type': {
			borderTop: `1px solid ${colors.gray[200]}`,
		},
	})
);

interface AccordionCustomCompareProps {
	res: Measure;
	expanded: { [key: string]: boolean };
	expandedScoring: { [key: string]: boolean };
	handleExpand: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
	handleExpandScoring: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}

const AccordionCustomCompare: React.FC<AccordionCustomCompareProps> = ({
	res,
	handleExpand,
	expanded,
	expandedScoring,
	handleExpandScoring,
}) => {
	const { data: criterias = [] } = useGetCriteriaTypes();

	const accordions = [
		{
			label: 'Description & Function',
			defaultExpanded: false,
			content: (
				<Box height="150px" overflow="auto">
					<div
						style={{
							marginTop: '10px',
							fontSize: '14px',
							fontWeight: '400',
							lineHeight: '20px',
							marginBottom: '12px',
							color: '#9DA4AE',
						}}
						dangerouslySetInnerHTML={{ __html: res?.description }}
					></div>
				</Box>
			),
		},
		{
			label: 'Potential application',
			defaultExpanded: true,
			content: (
				<Box>
					<Box width="100%" display="flex" alignItems="center" justifyContent="center" sx={{ aspectRatio: '1' }}>
						<Image
							style={{ objectFit: 'cover', maxWidth: '100%' }}
							src={res?.location?.image}
							alt="potential application"
						/>
					</Box>
					<Box height="54px" overflow="auto" mt="12px">
						<Typography fontSize="14px" fontWeight="500" lineHeight="18px" color="#6C737F" sx={{ textAlign: 'center' }}>
							{res?.location?.title}
						</Typography>
					</Box>
				</Box>
			),
		},
		...criterias
			.filter(it => it.is_scoring_guide)
			.map((it, idx) => ({
				label: (
					<Accordion
						key={`${it.id}`}
						sx={{ p: '0 !important', border: 'none', borderTop: '0 !important' }}
						expanded={!!expandedScoring[`${it.id}`]}
						onChange={handleExpandScoring(`${it.id}`)}
					>
						<AccordionSummary
							sx={{
								p: '0px!important',
								minHeight: 'unset',
								'.MuiAccordionSummary-content': { m: '0!important', width: 0 },
							}}
							expandIcon={
								<IconButton sx={{ p: 0, border: '1px solid gray' }}>
									<KeyboardArrowDownIcon sx={{ fontSize: '12px' }} />
								</IconButton>
							}
						>
							<Box
								display="flex"
								alignItems="center"
								width="100%"
								mr={0.5}
								sx={{ cursor: 'default' }}
								onClick={e => e.stopPropagation()}
							>
								<Typography fontSize="24px" fontWeight="600" mr={1} sx={{ flex: 1 }}>
									{it.name}
								</Typography>
								<RatingCustom value={res.criteria_types.find(x => x.id === it.id)?.measure_criteria_type?.point || 0} />
							</Box>
						</AccordionSummary>
						<AccordionDetails sx={{ px: 0, pb: 1, mt: 1, color: 'gray', height: '80px', overflow: 'auto' }}>
							<Typography>
								{res.criteria_types.find(x => x.id === it.id)?.measure_criteria_type?.description ||
									'No description available'}
							</Typography>
						</AccordionDetails>
					</Accordion>
				),
				defaultExpanded: true,
				content: (
					<Box display="flex" flexDirection="column" gap={2}>
						{it.criterias
							.filter(item => item.is_scoring_guide)
							.map((item, idy) => {
								const data = res.criterias.find(x => x.id === item.id);

								return (
									<Accordion
										key={`${idx}_${idy}`}
										sx={{ p: '0 !important', border: 'none', borderTop: '0 !important' }}
										expanded={!!expandedScoring[`${idx}_${idy}`]}
										onChange={handleExpandScoring(`${idx}_${idy}`)}
										onClick={e => e.stopPropagation()}
									>
										<AccordionSummary
											sx={{
												p: '0px!important',
												minHeight: 'unset',
												'.MuiAccordionSummary-content': { m: '0!important' },
											}}
											expandIcon={
												<IconButton sx={{ p: 0, border: '1px solid gray' }}>
													<KeyboardArrowDownIcon sx={{ fontSize: '12px' }} />
												</IconButton>
											}
										>
											<Box
												display="flex"
												alignItems="center"
												width="100%"
												mr={0.5}
												sx={{ cursor: 'default' }}
												onClick={e => e.stopPropagation()}
											>
												<Typography mr={1} sx={{ flex: 1 }}>
													{item.principle}
												</Typography>
												<RatingCustom value={data?.measure_criteria?.point || 0} />
											</Box>
										</AccordionSummary>
										<AccordionDetails sx={{ px: 0, pb: 1, mt: 1, color: 'gray', height: '80px', overflow: 'auto' }}>
											{data?.measure_criteria?.description || 'No description available'}
										</AccordionDetails>
									</Accordion>
								);
							})}
					</Box>
				),
			})),
		{
			label: 'Potential Combination with Other Measures',
			defaultExpanded: true,
			content: (
				<Box height="100px" overflow="auto">
					<ul style={{ paddingLeft: '20px' }}>
						{res.potentials.map((item: any, id: number) => (
							<li
								key={id}
								style={{
									marginTop: '10px',
									fontSize: '14px',
									fontWeight: '400',
									lineHeight: '20px',
									marginBottom: '12px',
									color: '#9DA4AE',
								}}
							>
								<Link to={`${ROUTES.MEASUREITEM}?id=${item.id}`} target="_blank" style={{ color: 'inherit ' }}>
									{item.name}
								</Link>
							</li>
						))}
					</ul>
				</Box>
			),
		},
	];

	return (
		<>
			{accordions.map((item, index) => {
				const panelKey = `panel_${index}`;
				if (expanded?.[panelKey] === undefined) {
					expanded[panelKey] = item.defaultExpanded;
				}
				return (
					<Accordion key={`accordion_${index}`} expanded={expanded[panelKey]} onChange={handleExpand(panelKey)}>
						<AccordionSummary
							sx={{ p: '0px!important' }}
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
						>
							<Box fontSize="24px" fontWeight="600" width={'100%'} onClick={e => e.stopPropagation()}>
								{item.label}
							</Box>
						</AccordionSummary>
						<AccordionDetails>{item.content}</AccordionDetails>
					</Accordion>
				);
			})}
		</>
	);
};

export default AccordionCustomCompare;
