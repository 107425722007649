import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material';
import { icon_addMore, icon_arrowLeft, icon_remove, icon_uploadPlus } from 'assets/icons';
import {
	FieldFormik,
	Fieldrow,
	FileInput,
	MultiSelect,
	RatingCustomAdmin,
	Select,
	TextEditor,
	Textarea,
} from 'components/atoms';
import { AdminLayout, ModalAdmin, ModalAdminCreate, ModalLocationCreate } from 'components/organisms';
import { createSchema, initialValue } from 'constants/form/measure-form';
import { Axios } from 'core/httpServices';
import { Field, FieldArray, Form, Formik } from 'formik';
import { pickBy } from 'lodash';
import React, { useLayoutEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ADMIN_ROUTES } from 'routers';

interface AdminCreateMeasureProps {}

const transformArrayToObject = (input: any) => {
	let c = [];
	for (let i = 0; i < input?.length; i++) {
		for (let e = 0; e < input[i]?.length; e++) {
			c.push(input[i][e]);
		}
	}
	return c;
};

const transformObjectToArray = (input: { [key: string]: string }) => {
	return Object.values(input);
};

const buttonStyling = {
	width: '100%',
	border: '1px dashed #E5E7EB',
	height: '110px',
};
const AdminCreateMeasure: React.FC<AdminCreateMeasureProps> = ({}) => {
	const [getData, setGetdata] = useState<any>();
	const [functionality, setFunctionality] = useState<any>();
	const [resUseProfiles_tags, setResUseProfiles_tags] = useState<any>();
	const [functionality_tags, setFunctionality_tags] = useState<any>(null);
	const [getCriteria, setGetCriteria] = useState<any>();
	const navigate = useNavigate();
	const [modalOpen, setModalOpen] = useState(false);
	const [modalOpenLocation, setModalLocation] = useState(false);
	const [state, setState] = useState<boolean>(false);
	// Pop up for Schematic
	const [modalOpenSchematic, setModalOpenSchematic] = useState<number>();

	const [applicationId, setApplicationId] = useState(0);

	const [filters, setFilters] = useState();

	const getDatalist = async () => {
		try {
			const getData = await Axios.get(`/tag-types/all?&includes=tags,criterias,location&sort=-created_at`);
			const useProfiles_tags = getData.data.data
				.filter((e: any) => e.slug === 'land_use_profiles')
				.map((req: any) => req.tags);

			const usefunctionality_tags = getData.data.data
				.filter((e: any) => e.slug === 'functionality')
				.map((req: any) => req.tags);

			setFunctionality(usefunctionality_tags[0]);
			setResUseProfiles_tags(useProfiles_tags[0]);
			setGetdata(getData.data.data);
		} catch {
			console.log('error create measure');
		}
	};

	const getCriteriaList = async () => {
		const getCriteria = await Axios.get(`/criteria-types?includes=criterias,criteria_types&sort=created_at`);
		setGetCriteria(getCriteria.data.data);
	};
	const getFunctionality_tags = async () => {
		try {
			const getTags = await Axios.get(`/measures?limit=200&sort=-created_at`);
			setFunctionality_tags(getTags.data.data);
		} catch {
			console.log('error');
		}
	};
	useLayoutEffect(() => {
		getDatalist();
		getCriteriaList();
		getFunctionality_tags();
	}, [state]);

	return (
		<AdminLayout>
			<section>
				<Formik
					initialValues={{ ...initialValue }}
					validationSchema={createSchema}
					onSubmit={values => {}}
					validateOnMount
				>
					{({ values, setTouched, touched, setFieldValue }: any) => {
						const postData = async () => {
							const data = new FormData();
							const attachment = [...values.photoSchematic];

							if (values.Typology?.length > 0) {
								const cleanedTypology = pickBy(values.Typology, v => v !== undefined);
								const TypologyArray = transformObjectToArray(cleanedTypology);
								if (values.Typology?.length === 1) {
									//@ts-ignore
									data.append('tag_ids[]', TypologyArray);
								} else if (values.Typology?.length > 1) {
									TypologyArray.forEach((element: any) => data.append('tag_ids', element));
								}
							}
							if (values.useProfiles?.length > 0) {
								const tag_type = [...values?.useProfiles.map((e: any) => e.id)];
								if (values.useProfiles?.length === 1) {
									//@ts-ignore
									data.append('tag_ids[]', tag_type);
								} else if (values.useProfiles?.length > 1) {
									tag_type.forEach((element: any) => data.append('tag_ids', element));
								}
							}
							if (values.potential) {
								let tag_potential = values?.potential.map((e: any) => e.id);
								if (tag_potential?.length === 1) {
									//@ts-ignore
									data.append('potential_ids[]', tag_potential);
								} else if (tag_potential?.length > 1) {
									tag_potential.forEach((element: any) => data.append('potential_ids', element));
								}
							}

							data.append('category_id', values.Functionality);
							data.append('name', values.measurename);
							data.append('description', values.description);
							if (values.pdfFile) {
								data.append('pdf_file', values.pdfFile);
								data.append('pdf_key', 'pdf_key');
							}
							data.append('functions', values.functions);

							data.append('location[title]', values.location?.title || 'No title');
							data.append('location[description]', values.location?.description || 'No description');
							values.location.file && data.append('location[image]', values.location.file);
							// Append data : Application example
							values.application
								?.filter((it: any) => !!it)
								?.forEach((it: any, i: number) => {
									data.append(`applications[${i}][title]`, it.title || 'No title');
									data.append(`applications[${i}][description]`, it.description || 'No description');
									data.append(`applications[${i}][image]`, it.image);
								});
							// Append data : Schematic and Photo3d
							if (attachment.length > 0) {
								for (let i = 0; i < attachment.length; i++) {
									data.append(`attachments[${i}][name]`, attachment[i]?.name || 'No title');
									data.append(`attachments[${i}][image]`, attachment[i]?.file);
								}
							}

							// Append data : Criteria
							for (let i = 0; i < transformArrayToObject(getCriteria?.map((e: any) => e.criterias)).length; i++) {
								data.append(
									`criterias[${i}][criteria_id]`,
									transformArrayToObject(getCriteria?.map((e: any) => e.criterias))[i].id
								);
								data.append(`criterias[${i}][description]`, values.scoringTechnicalPerformance[i]?.description || '');
								data.append(`criterias[${i}][point]`, values.scoringTechnicalPerformance[i]?.point || 0);
							}

							getCriteria?.forEach((it: any, i: any) => {
								data.append(`criteria_types[${i}][criteria_type_id]`, it.id);
								data.append(
									`criteria_types[${i}][description]`,
									values?.scoringParentTechnicalPerformance?.[i]?.description || ''
								);
								data.append(`criteria_types[${i}][point]`, values.scoringParentTechnicalPerformance?.[i]?.point || 0);
							});

							try {
								const getData = await Axios.post(`/measures`, data, {
									headers: {
										'Content-Type': 'multipart/form-data',
									},
								});
								if (getData.status === 200) {
									toast.success(`Measure ${values.measurename} has been created successfully`);
									navigate(ADMIN_ROUTES.INTRODUCE);
								}
							} catch (e: any) {
								// Management Error Toasty
								const errorType = e.response?.data?.error?.errors;
								errorType.forEach((element: any) => {
									if (element.message.indexOf('name') !== -1) {
										const alert = element.message.replace('name', 'Measure name');
										toast.error(alert);
									} else if (element.message.indexOf('category_id') !== -1) {
										const alert = element.message.replace('category_id', 'Functionality');
										toast.error(alert);
									} else {
										toast.error(element.message);
									}
								});
							}
						};

						const clearValues = (name: string, id: number) => {
							if (name === 'application') {
								setFieldValue(`application[${id}]`, null);
							}
						};

						const onChangeRating = (items: any, opt: any, index: number) => {
							setFilters(items);
						};

						const onPdfChange = (pdf?: File) => {
							setFieldValue(`pdfFile`, pdf);
						};

						return (
							<Form>
								<Container
									maxWidth="xl"
									sx={{ '& a': { textDecoration: 'none' }, px: { xs: '0 !important', md: '16px !important' } }}
								>
									<Box display="flex" alignItems="center" width="100%" flexWrap="wrap" gap={2}>
										<Box display="flex" alignItems="center" flex={1} flexShrink={0} ml={-3}>
											<Link to="/admin/introduce">
												<Button>
													<img src={icon_arrowLeft} alt="icon_arrowLeft" />
												</Button>
											</Link>
											<Typography ml={2} variant="h5" fontWeight="bold" whiteSpace="nowrap">
												Create new measure
											</Typography>
										</Box>
										<Box display="flex">
											<Link to="/admin/introduce">
												<Button
													variant="outlined"
													sx={{
														textDecoration: 'none!important',
														background: 'white',
														color: 'black',
														border: '1px solid #D0D5DD',
													}}
												>
													Cancel
												</Button>
											</Link>
											<Button onClick={() => postData()} sx={{ ml: 3 }} variant="contained">
												Save
											</Button>
										</Box>
									</Box>
								</Container>
								<Divider sx={{ my: 3 }} />
								<Container maxWidth="xl" sx={{ px: { xs: '0 !important', md: '16px !important' } }}>
									<Grid container justifyContent="space-between">
										<Grid md={3} xs={12} item>
											<Typography sx={{ mb: 2 }} fontWeight="600">
												Photo
											</Typography>
										</Grid>
										<Grid md={8} xs={12} item>
											<Container sx={{ mb: 4 }}>
												<Typography fontWeight="600">Photo of measure</Typography>
												<Typography sx={{ mb: 2 }}>Upload up to three (6) photos</Typography>
												<Box sx={{ display: 'flex' }}>
													<Box sx={{ width: '30%', marginRight: '10px' }}>
														<Button sx={buttonStyling} onClick={() => setModalOpenSchematic(0)}>
															<img src={icon_uploadPlus} alt="icon upload" />
														</Button>
														{values?.photoSchematic[0]?.file?.preview && (
															<img
																style={{ width: '100px', height: '60px' }}
																className="image-contain"
																src={values?.photoSchematic[0]?.file?.preview}
															/>
														)}
													</Box>
													{[1, 2, 3, 4, 5].map(
														(it: any, id: number) =>
															it <= values?.photoSchematic?.length && (
																<Box key={id} sx={{ width: '30%', marginRight: '10px' }}>
																	<Button sx={buttonStyling} onClick={() => setModalOpenSchematic(it)}>
																		<img src={icon_uploadPlus} alt="icon upload" />
																	</Button>
																	{values?.photoSchematic[it]?.file?.preview && (
																		<img
																			style={{ width: '100px', height: '60px' }}
																			className="image-contain"
																			src={values?.photoSchematic[it]?.file?.preview}
																		/>
																	)}
																</Box>
															)
													)}
												</Box>
											</Container>

											<Container sx={{ mb: 4 }}>
												<Typography fontWeight="600">Location of Potential Application</Typography>
												<Typography sx={{ mb: 2 }}>Upload Potential Application of measure</Typography>
												<Box sx={{ display: 'flex' }}>
													<Box sx={{ width: '30%', marginRight: '10px' }}>
														<Button sx={buttonStyling} onClick={() => setModalLocation(true)}>
															<img src={icon_uploadPlus} alt="icon upload" />
														</Button>
														{!!values?.location?.file?.preview && (
															<img
																style={{ width: '100px', height: '60px' }}
																className="image-contain"
																src={values?.location?.file?.preview}
															/>
														)}
													</Box>
												</Box>
											</Container>
										</Grid>
									</Grid>
								</Container>
								<Divider sx={{ my: 3 }} />

								<Container maxWidth="xl" sx={{ px: { xs: '0 !important', md: '16px !important' } }}>
									<Grid container justifyContent="space-between">
										<Grid md={3} xs={12} item>
											<Typography sx={{ mb: 2 }} fontWeight="600">
												General Information
											</Typography>
										</Grid>
										<Grid md={8} xs={12} item>
											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Measure name *
												</Typography>
												<Fieldrow name="measurename">
													<FieldFormik
														type="text"
														placeholder="ABC Waters design features, etc.."
														name="measurename"
														readonly={false}
													/>
												</Fieldrow>
											</Container>

											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Functionality *
												</Typography>
												<Field
													style={{
														width: '100%',
														padding: '10px 15px',
														border: '1px solid #D0D5DD',
														borderRadius: '8px',
													}}
													as="select"
													name="Functionality"
													value={values.Functionality}
												>
													<option disabled value="">
														Make a choice
													</option>
													{functionality &&
														functionality !== undefined &&
														functionality.map((u: any, idx: number) => (
															<option value={u.id} key={u.name}>
																{u.name}
															</option>
														))}
												</Field>
											</Container>
											<FieldArray
												name="Typology"
												render={arrayHelpers => (
													<Box>
														{getData &&
															getData !== undefined &&
															getData
																.filter(
																	(data: any) => data.slug !== 'land_use_profiles' && data.slug !== 'functionality'
																)
																.map((option: any, index: number) => (
																	<Container key={index} maxWidth="md" sx={{ mb: 4 }}>
																		<Typography sx={{ mb: 2 }} fontWeight="600">
																			{option.name}
																		</Typography>
																		<Select name={`Typology.${index}`}>
																			<option value="" selected disabled>
																				Make a choice
																			</option>
																			{option &&
																				option !== undefined &&
																				option.tags.map((optionItem: any, idx: number) => (
																					<option value={optionItem.id} key={optionItem.name}>
																						{optionItem.name}
																					</option>
																				))}
																		</Select>
																	</Container>
																))}
													</Box>
												)}
											/>
											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Land Use Profiles
												</Typography>
												<MultiSelect
													options={resUseProfiles_tags && resUseProfiles_tags}
													selectedValues={values.useProfiles}
													name="useProfiles"
													onBlur={() => !touched.useProfiles && setTouched({ ...touched, useProfiles: true })}
												/>
											</Container>
											<Container maxWidth="md" sx={{ mb: 4 }}>
												<Typography sx={{ mb: 2 }} fontWeight="600">
													Description & Function
												</Typography>
												<Field name="description">
													{({ field, meta }: any) => (
														<TextEditor
															value={values.description}
															onchange={field.onChange(field.name)}
															pdf
															onPdfChange={onPdfChange}
															maxLength={1000}
														/>
													)}
												</Field>
											</Container>
										</Grid>
									</Grid>
								</Container>
								<Divider sx={{ my: 3 }} />

								<Container maxWidth="xl" sx={{ px: { xs: '0 !important', md: '16px !important' } }}>
									<Grid container justifyContent="space-between">
										<Grid md={3} xs={12} item>
											<Typography sx={{ mb: 2 }} fontWeight="600">
												Scoring
											</Typography>
										</Grid>
										<Grid md={8} xs={12} item>
											{getCriteria &&
												getCriteria.map((opt: any, index: number) => {
													const activeCriterias = opt?.criterias?.filter((it: any) => it.is_scoring_guide);
													return (
														<Container key={index} maxWidth="md" hidden={!opt.is_scoring_guide}>
															<Container
																maxWidth="md"
																sx={{
																	mb: 4,
																	display: 'flex',
																	alignItems: 'flex-start',
																	flexDirection: 'column',
																	px: '0 !important',
																	position: 'relative',
																	'.character-left': {
																		pl: 3.5,
																	},
																}}
															>
																<Box px={2} py={1.25} bgcolor="grey.100" borderRadius={'8px'} width={'100%'}>
																	<Typography fontWeight="600">{opt.name}</Typography>
																</Box>
																<RatingCustomAdmin
																	name={`scoringParentTechnicalPerformance[${index}]`}
																	ids={opt.id}
																	onChange={onChangeRating}
																	readonly={false}
																	value={0}
																	title=""
																/>
																<Textarea
																	name={`scoringParentTechnicalPerformance[${index}].description]`}
																	placeholder="Enter a description..."
																	maxLength={500}
																/>
																{!!activeCriterias?.length && (
																	<Box
																		position="absolute"
																		width="1px"
																		bgcolor="#D0D5DD"
																		top="calc(100% - 36px)"
																		left="16px"
																		height={`calc(77px + ${(activeCriterias.length - 1) * 235.52}px)`}
																	/>
																)}
															</Container>
															{opt &&
																opt !== undefined &&
																opt.criterias.map((items: any, id: number) =>
																	transformArrayToObject(getCriteria?.map((item: any) => item.criterias)).map(
																		(data: any, idx: number) =>
																			data.id === items.id && (
																				<>
																					<Container
																						key={id}
																						maxWidth="md"
																						sx={{
																							pb: 4,
																							display: 'flex',
																							alignItems: 'flex-start',
																							flexDirection: 'column',
																							pl: '40px !important',
																							pr: '0 !important',
																						}}
																						hidden={!items.is_scoring_guide}
																					>
																						<Box position="relative">
																							<Typography fontWeight="500" variant="body2" mb={-0.5}>
																								{items.principle}
																							</Typography>
																							<Box
																								position="absolute"
																								height="1px"
																								bgcolor="#D0D5DD"
																								width={'16px'}
																								top="50%"
																								left="-23px"
																							/>
																						</Box>
																						<RatingCustomAdmin
																							name={`scoringTechnicalPerformance[${idx}]`}
																							ids={items.id}
																							onChange={onChangeRating}
																							readonly={false}
																							value={0}
																							title=""
																						/>
																						<Textarea
																							name={`scoringTechnicalPerformance[${idx}].description]`}
																							placeholder="Enter a description..."
																							maxLength={500}
																						/>
																					</Container>
																				</>
																			)
																	)
																)}
														</Container>
													);
												})}
										</Grid>
									</Grid>
								</Container>
								<Divider sx={{ my: 3 }} />
								<Container maxWidth="xl" sx={{ px: { xs: '0 !important', md: '16px !important' } }}>
									<Grid container justifyContent="space-between">
										<Grid md={3} xs={12} item>
											<Typography sx={{ mb: 2 }} fontWeight="600">
												Application example
											</Typography>
										</Grid>
										<Grid md={8} xs={12} item>
											{['first', 'second', 'third'].map((items: any, id: number) => {
												const application = values?.application?.[id];
												return (
													<Container
														key={id}
														maxWidth="md"
														sx={{
															mb: 4,
															background: '#FCFCFD',
															border: '1px solid #E5E7EB',
															borderRadius: '8px',
															padding: '0 !important',
														}}
													>
														<Button
															sx={{
																textTransform: 'none',
																display: 'flex',
																position: 'relative',
																justifyContent: 'flex-start',
																px: 2,
																py: 1.5,
																flexWrap: { xs: 'wrap', lg: 'unset' },
																gap: 1,
																minHeight: '64px',
															}}
															onClick={() => {
																setModalOpen(true);
																setApplicationId(id);
															}}
															fullWidth
														>
															{application?.image?.preview || application?.application_image_url ? (
																<Box
																	width="22%"
																	minWidth="130px"
																	mr={1}
																	pt={application?.title || application?.description ? 1 : 0}
																	flexShrink={0}
																	alignSelf="flex-start"
																>
																	<img
																		src={application?.image?.preview || application?.application_image_url}
																		className="image-contain"
																	/>
																</Box>
															) : (
																<Box mr={1}>
																	<img src={icon_addMore} alt="add more" />
																</Box>
															)}
															<Box display="flex" alignItems="center">
																{application?.title || application?.description ? (
																	<Box mr={4} textAlign="left">
																		{application?.title && (
																			<Typography mb={1} sx={{ color: '#344054' }} fontWeight="600">
																				{application?.title}
																			</Typography>
																		)}
																		<Typography sx={{ color: '#475467' }}>{application?.description}</Typography>
																	</Box>
																) : (
																	<Typography sx={{ color: '#384250' }}>Add application example</Typography>
																)}
															</Box>
															{application && (
																<Box position="absolute" top={4} right={4}>
																	<Button
																		onClick={e => {
																			e.stopPropagation();
																			clearValues('application', id);
																		}}
																		sx={{ minWidth: 'fit-content', height: 'fit-content' }}
																	>
																		<img src={icon_remove} alt="remove logo" />
																	</Button>
																</Box>
															)}
														</Button>
													</Container>
												);
											})}
										</Grid>
									</Grid>
								</Container>
								<Divider sx={{ my: 3 }} />
								<Container sx={{ mb: 20, px: { xs: '0 !important', md: '16px !important' } }} maxWidth="xl">
									<Grid container justifyContent="space-between">
										<Grid md={3} xs={12} item>
											<Typography sx={{ mb: 2 }} fontWeight="600">
												Potential combination with other measures
											</Typography>
										</Grid>
										<Grid md={8} xs={12} item>
											{functionality_tags && (
												<MultiSelect
													options={functionality_tags}
													selectedValues={values.potential}
													placeholders="Choose measure"
													name="potential"
													onBlur={() => !touched.potential && setTouched({ ...touched, potential: true })}
												/>
											)}
										</Grid>
									</Grid>
								</Container>

								{/* ////////////// Modal ///////////// */}
								<ModalAdminCreate
									openModal={Number.isInteger(modalOpenSchematic)}
									id={modalOpenSchematic}
									name="photoSchematic"
									handleClose={() => setModalOpenSchematic(undefined)}
								/>
								<ModalLocationCreate
									openModal={modalOpenLocation}
									name="location"
									handleClose={() => setModalLocation(false)}
									setTouched={() => !touched.location && setTouched({ ...touched, location: true })}
								/>

								<ModalAdmin isOpen={modalOpen} handleClose={() => setModalOpen(false)}>
									<Container maxWidth="xl" sx={{ mb: 4 }}>
										<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
											<Typography
												fontSize="24px"
												sx={{
													lineHeight: {
														md: '24px',
														xs: '18px',
													},
													my: '30px',
													fontWeight: '600',
												}}
											>
												Add application example
											</Typography>
											<Button sx={{ fontSize: '20px', color: 'black' }} onClick={() => setModalOpen(false)}>
												X
											</Button>
										</Box>
										<Box>
											<Typography fontWeight="600">Photo of application</Typography>
											<Typography sx={{ mb: 2 }}>
												Please resize the picture to exactly 1920x1080px before uploading it
											</Typography>
											<Box
												width="100%"
												sx={{
													justifyContent: 'center',
													display: 'flex',
													flexDirection: 'column',
												}}
											>
												<FileInput
													name={`application[${applicationId}].image`}
													label=""
													maxsize="SVG, PNG, JPG or GIF (max. 1920x1080px)"
													setTouched={() => !touched.application && setTouched({ ...touched, application: true })}
													ThumbTrigger
													previewImg={
														values?.application[applicationId]?.image?.preview ||
														values?.application[applicationId]?.application_image_url
													}
												/>
											</Box>
											<Typography sx={{ my: 2 }} fontWeight="600">
												Application name
											</Typography>
											<FieldFormik
												type="text"
												placeholder="ABC Waters design features, etc.."
												name={`application[${applicationId}].title`}
												readonly={false}
												maxLength={127}
											/>
											<Typography sx={{ my: 2 }} fontWeight="600">
												Description
											</Typography>
											<Textarea
												name={`application[${applicationId}].description`}
												value={values?.application?.[applicationId]?.description || ''}
												placeholder="Enter a description..."
												maxLength={500}
											/>
										</Box>
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'flex-end',
												mt: '10px',
											}}
										>
											<Button
												sx={{ mr: '10px' }}
												onClick={() => {
													clearValues('application', applicationId);
													setModalOpen(false);
												}}
												variant="contained"
											>
												Delete
											</Button>
											<Button onClick={() => setModalOpen(false)} variant="contained">
												Apply
											</Button>
										</Box>
									</Container>
								</ModalAdmin>
							</Form>
						);
					}}
				</Formik>
			</section>
		</AdminLayout>
	);
};
export default AdminCreateMeasure;
