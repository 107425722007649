import { Box, Button, Container, Typography } from '@mui/material';
import { FieldFormik, FileInput } from 'components/atoms';
import { ModalAdmin } from 'components/organisms';
import { useFormikContext } from 'formik';
import React from 'react';

interface Props {
	handleClose: () => void;
	name: string;
	openModal?: boolean;
	id?: any;
}

const ModalAdminCreate: React.FC<Props> = props => {
	const { handleClose, name, openModal, id = null } = props;
	const { values } = useFormikContext<any>();

	const clearValues = () => {
		values.photoSchematic.splice(id, 1);
		handleClose();
	};

	return (
		<ModalAdmin isOpen={openModal} handleClose={handleClose}>
			<Container maxWidth="xl" sx={{ mb: 4 }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Typography
						fontSize="24px"
						sx={{
							lineHeight: {
								md: '24px',
								xs: '18px',
							},
							my: '30px',
							fontWeight: '600',
						}}
					>
						Add photo
					</Typography>
					<Button sx={{ fontSize: '20px', color: 'black' }} onClick={handleClose}>
						X
					</Button>
				</Box>
				<Box>
					<Typography sx={{ mt: 2 }} fontWeight="600">
						Photo of application
					</Typography>
					<Typography sx={{ mb: 2 }}>Please resize the picture to exactly 1920x1080px before uploading it</Typography>
					<Box sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
						<FileInput
							name={`${name}[${id}].file`}
							label=""
							maxsize="SVG, PNG, JPG or GIF (max. 1920x1080px)"
							ThumbTrigger
							previewImg={
								props.name === 'photoSchematic'
									? values?.photoSchematic[id]?.file?.preview
									: values?.photo3D[id]?.file?.preview
							}
						/>
					</Box>
					<>
						<Typography sx={{ my: 2 }} fontWeight="600">
							Title
						</Typography>
						<FieldFormik
							type="text"
							placeholder="ABC Waters design features, etc.."
							name={id !== null ? `${name}[${id}].name` : `${name}[name]`}
							readonly={false}
							maxLength={250}
						/>
					</>
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '10px' }}>
					<Button
						sx={{ mr: '10px' }}
						onClick={() => {
							clearValues();
						}}
						variant="contained"
					>
						Delete
					</Button>
					<Button variant="contained" onClick={handleClose}>
						Apply
					</Button>
				</Box>
			</Container>
		</ModalAdmin>
	);
};

export default ModalAdminCreate;
