import { object, string } from 'yup';

export const filterSchema = object({
	name: string().required('Filter name is required'),
	description: string().required('Filter description is required'),
});

export const defaultInitialCriteriaValues = {
	name: '',
	description: '',
};
