import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { FieldFormik, Fieldrow, Textarea } from 'components/atoms';
import { Formik } from 'formik';
import { useCallback, useEffect } from 'react';

import { difference } from 'lodash';
import { defaultInitialCriteriaValues, filterSchema } from './filterSchema';

export const filterColors = ['#0000ff', '#008000', '#00c6ff', '#ff0000', '#ffa500'];

const FilterModal = (props: any) => {
	const { isOpen = false, onCancel, onOk, initialValues, usedColors } = props;

	const handleSubmit = useCallback(
		async (values: any, action: any) => {
			try {
				onOk && (await onOk(values));
			} catch (err) {
				action.setSubmitting(false);
			}
		},
		[onOk]
	);

	return (
		<Dialog open={isOpen} onClose={onCancel} fullWidth={true} maxWidth="sm">
			<DialogTitle>
				{initialValues ? 'Edit' : 'Add'} filter
				<IconButton
					onClick={onCancel}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: theme => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Formik
					initialValues={initialValues || defaultInitialCriteriaValues}
					validationSchema={filterSchema}
					onSubmit={handleSubmit}
				>
					{({ isValid, dirty, isSubmitting, handleSubmit, values, setFieldValue }) => {
						useEffect(() => {
							if (!values.color) {
								setFieldValue('color', difference(filterColors, usedColors)[0]);
							}
						}, []);

						return (
							<form onSubmit={handleSubmit}>
								<Stack spacing={2}>
									<Fieldrow name="name">
										<FieldFormik
											type="text"
											label="Filter name"
											placeholder="Enter a filter name"
											name="name"
											readonly={false}
										/>
									</Fieldrow>
								</Stack>
								<Stack spacing={2} mt={2}>
									<Fieldrow name="description">
										<Textarea
											label="Description"
											placeholder="Enter a description"
											name="description"
											value={values.description}
										/>
									</Fieldrow>
								</Stack>
								<Typography mt={2} sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
									Filter color
								</Typography>
								<Box display={'flex'} alignItems={'center'} gap={1.5} mt={1}>
									{filterColors.map(it => (
										<Box
											key={it}
											borderRadius={'50%'}
											bgcolor={it}
											width={'32px'}
											height="32px"
											border={it === values.color ? '3px solid black' : ''}
											onClick={() => setFieldValue('color', it)}
											sx={{
												cursor: 'pointer',
											}}
										></Box>
									))}
								</Box>
								<Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }} mt="24px">
									<Button variant="outlined" onClick={onCancel}>
										Cancel
									</Button>
									<LoadingButton variant="contained" type="submit" loading={isSubmitting} disabled={!dirty || !isValid}>
										Save
									</LoadingButton>
								</Box>
							</form>
						);
					}}
				</Formik>
			</DialogContent>
		</Dialog>
	);
};

export default FilterModal;
