import { Box, Button, Card, CardActions, CardContent, CardMedia, IconButton, Typography } from '@mui/material';
import { Tooltips } from 'components/atoms';
import { Measure } from 'modules/measure';
import * as React from 'react';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { colors } from 'styles';

interface MeasuresCardProps {
	data: Measure;
	image_url: string;
	disabled?: boolean;
	handleCompare: (data: Measure) => void;
	onCardClick?: () => void;
}

const MeasuresCard: React.FC<MeasuresCardProps> = (props: MeasuresCardProps) => {
	const { data, image_url, disabled, handleCompare, onCardClick } = props;

	const ButtonStyling = {
		background: colors.gray[100],
		borderRadius: '10px',
		maxWidth: '60%',
		margin: '10px 0',
		color: colors.gray[700],
		fontSize: '12px',
		font: 'inter',
		lineHeight: '20px',
		fontWeight: '500',
		pointerEvents: 'none',
	};

	return (
		<Card
			sx={{
				opacity: disabled ? 0.9 : 1,
				height: '100%',
				display: { sm: 'flex' },
				flexDirection: 'column',
				cursor: 'pointer',
				boxShadow: 'none',
				borderRadius: '8px',
				border: '1.5px solid #F0F0F0',
				transition: 'background 150ms linear',
				'&:hover': {
					background: colors.gray[50],
				},
				pointerEvents: disabled ? 'none' : 'unset',
				position: 'relative',
			}}
		>
			{disabled && (
				<Box position="absolute" sx={{ inset: 0, opacity: 0.8, pointerEvents: 'none' }} zIndex={1} bgcolor="#F9F9F9" />
			)}
			<Link to={`/measureItem?id=${data.id}`}>
				<CardMedia
					component="img"
					height="160"
					image={image_url}
					sx={{
						objectFit: 'contain',
						background: 'white',
					}}
				/>
			</Link>

			<CardContent
				sx={{
					display: 'flex',
					alignContent: 'flex-start',
					justifyContent: 'space-between',
				}}
			>
				<Box>
					<Typography
						sx={{
							fontFamily: 'Inter',
							textTransform: 'uppercase',
							fontSize: '12px',
							fontWeight: '700',
							lineHeight: '18px',
							fontStyle: 'normal',
							letterSpacing: '0.08em',
						}}
						gutterBottom
						component="div"
						color={data?.category?.color}
					>
						{data.category?.name}
					</Typography>
					<Link
						to={`/measureItem?id=${data.id}`}
						style={{
							textDecoration: 'none',
							fontFamily: 'Inter',
							fontWeight: '600',
							lineHeight: '24px',
							fontSize: '18px',
							color: '#000000',
						}}
						onClick={() => onCardClick && onCardClick()}
					>
						<Typography
							sx={{
								fontFamily: 'Inter',
								fontWeight: '600',
								lineHeight: '24px',
								fontSize: '18px',
								'&:hover': {
									textDecoration: 'underline',
								},
							}}
						>
							{data.name}
						</Typography>
					</Link>
				</Box>
				<Tooltips title={'Add to compare'}>
					<Box>
						<IconButton
							onClick={() => handleCompare(data)}
							sx={{ border: 1, borderColor: 'grey.400', borderRadius: '8px' }}
						>
							<FiPlus />
						</IconButton>
					</Box>
				</Tooltips>
			</CardContent>
			<CardActions>
				{data?.tags.map((items, id: number) => (
					<React.Fragment key={`tags_${id}`}>
						{id < 2 && (
							<Button sx={ButtonStyling} size="small">
								<p
									style={{
										textOverflow: 'ellipsis',
										overflow: 'hidden',
										whiteSpace: 'nowrap',
										margin: '0px',
										fontFamily: 'Inter',
										fontWeight: '500',
										fontSize: '14px',
										textTransform: 'none',
										color: items.color,
									}}
								>
									{items.name}
								</p>
							</Button>
						)}
					</React.Fragment>
				))}
				{data?.tags && data?.tags?.length > 2 && (
					<Button sx={ButtonStyling} size="small">
						+ {data?.tags?.length - 2}
					</Button>
				)}
			</CardActions>
		</Card>
	);
};

export default MeasuresCard;
