import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { AdminLayout } from 'components/organisms';
import { useDownloadToolkit } from 'modules/toolkit';

const DownloadToolkit = () => {
	const { mutate: downloadToolkit, isLoading } = useDownloadToolkit();

	return (
		<>
			{isLoading && (
				<Box position={'fixed'} sx={{ inset: 0 }} zIndex={9999}>
					<Box
						width={'100%'}
						height="100%"
						position={'relative'}
						display={'flex'}
						alignItems={'center'}
						justifyContent={'center'}
						flexDirection={'column'}
					>
						<Box position={'absolute'} sx={{ inset: 0 }} bgcolor={'#423f3f12'} />
						<CircularProgress />
						<Typography mt={3} ml={1.5}>
							Downloading...
						</Typography>
					</Box>
				</Box>
			)}
			<AdminLayout>
				<Button sx={{ ml: 2 }} variant="contained" startIcon={<CloudDownloadIcon />} onClick={() => downloadToolkit()}>
					Download
				</Button>
			</AdminLayout>
		</>
	);
};

export default DownloadToolkit;
