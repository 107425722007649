import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Container,
	Grid,
	IconButton,
	Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Carousels } from 'components/atoms';
import RatingCustom from 'components/atoms/RatingCustom';
import { useResponsive } from 'constants/hooks';
import { NetworkContext } from 'providers/context';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import StickyBox from 'react-sticky-box';
import { ROUTES } from 'routers';
import { colors } from 'styles';
import Modal from '../../organisms/Modal/index';

interface Props {
	value: any;
	criteriaList: any;
}

const MenuBar = [
	'Description & Function',
	'Potential Application',
	'Potential Combination',
	'Scoring',
	'Application Examples',
];

const MenuTable = ['PILLAR', 'CRITERIA', 'SCORING', 'DESCRIPTION'];
const MenuTablePillarMeasure = ['PILLAR', 'SCORING', 'DESCRIPTION'];
const MenuTableCriteria = ['PILLAR', 'CRITERIA', 'DEFINITION'];
const MenuTablePillar = ['PILLAR', 'DEFINITION'];

const TableStyle = styled('table')(() => ({
	width: '100%',
	textAlign: 'center',
	marginTop: '30px',
	'& td, & th': {
		border: '1px solid #F0F0F0',
		borderRadius: '10px',
		padding: '8px',
		height: '100px !important',
		'@media (min-width: 900px)': {
			height: '100px !important',
		},
		'@media (max-width: 900px)': {
			height: '100% !important',
		},
	},
	'& td': {
		textAlign: 'left',
		fontFamily: 'Inter',
		fontSize: '16px',
		lineHeigh: '24px',
		fontWieght: '600',
	},
	'& th': {
		background: 'white!important',
		fontFamily: 'Inter',
		fontSize: '18px',
		lineHeigh: '24px!important',
		fontWieght: '600',
	},
	'& tr:nth-of-type(even)': {
		background: '#F5FAFF',
	},
}));

const ButtonDescriptionStyling = {
	background: colors.gray[100],
	borderRadius: '10px',
	color: '#344054',
	fontSize: '14px',
	fontWeight: '600',
	padding: '3px 15px',
	fontFamily: 'Inter',
	lineHeight: '18px',
	textTransform: 'none',
	pointerEvents: 'none',
};

enum MENU_ITEMS {
	DESCRIPTION,
	POTENTIAL,
	TECHNICAL,
	SCORING,
	APPLICATION_EXAMPLE,
}

const MeasureItemDetail: React.FC<Props> = props => {
	const { value = '', criteriaList } = props;
	const [modalOpen, setmodalOpen] = useState(false);
	const isDesktop = useResponsive('down', 'sm');
	const { isOnline } = useContext(NetworkContext);
	const scrollToDescriptionRef = useRef<HTMLLinkElement>(null);
	const scrollToApplicationExampleRef = useRef<HTMLLinkElement>(null);
	const scrollToPotentialRef = useRef<HTMLLinkElement>(null);
	const scrollToTechnicalRef = useRef<HTMLLinkElement>(null);
	const scrollToScoringRef = useRef<HTMLLinkElement>(null);
	const [descriptionText, setDescriptionText] = useState('');
	const [scoringView, setScoringView] = useState<'pillar' | 'criteria'>('pillar');
	const [scoringViewMeasure, setScoringViewMeasure] = useState<'pillar' | 'criteria'>('pillar');
	const executeScroll = (value: number) => {
		if (value === MENU_ITEMS.DESCRIPTION) {
			scrollToDescriptionRef.current?.scrollIntoView();
		} else if (value === MENU_ITEMS.POTENTIAL) {
			scrollToPotentialRef.current?.scrollIntoView();
		} else if (value === MENU_ITEMS.TECHNICAL) {
			scrollToTechnicalRef.current?.scrollIntoView();
		} else if (value === MENU_ITEMS.SCORING) {
			scrollToScoringRef.current?.scrollIntoView();
		} else if (value === MENU_ITEMS.APPLICATION_EXAMPLE) {
			scrollToApplicationExampleRef.current?.scrollIntoView();
		}
	};

	const [DescriptionRef, inViewDescription] = useInView({
		threshold: 0.1,
	});
	const [ApplicationExampleRef, inViewApplicationExample] = useInView({
		threshold: 0.1,
	});
	const [PotentialRef, inViewPotential] = useInView({
		threshold: 0.1,
	});
	const [TechnicalRef, inViewTechnical] = useInView({
		threshold: 0.1,
	});
	const [ScoringRef, inViewScoring] = useInView({
		threshold: 0.1,
	});
	const activeTab = () => {
		if (inViewDescription) {
			return MENU_ITEMS.DESCRIPTION;
		} else if (inViewPotential) {
			return MENU_ITEMS.POTENTIAL;
		} else if (inViewTechnical) {
			return MENU_ITEMS.TECHNICAL;
		} else if (inViewScoring) {
			return MENU_ITEMS.SCORING;
		} else if (inViewApplicationExample) {
			return MENU_ITEMS.APPLICATION_EXAMPLE;
		}
	};

	useEffect(() => {
		if (value?.description && value?.pdf) {
			const element = document.createElement('div');
			element.innerHTML = value?.description;
			element.querySelectorAll('pdf[pdf=true]').forEach((e: any) => {
				const a = document.createElement('a');
				a.href = isOnline ? value.pdf.url : value.pdf.path;
				a.target = '_blank';
				a.innerText = e.innerText;
				e.replaceWith(a);
			});
			setDescriptionText(element.innerHTML);
		} else {
			setDescriptionText(value?.description);
		}
	}, [value]);

	return (
		<Grid
			container
			mb="100px"
			px={{
				xs: '0px',
				md: '2rem',
			}}
		>
			<Grid
				item
				xs={12}
				md={3}
				sm={3}
				display="flex"
				flexDirection="column"
				justifyContent="flex-start"
				textAlign="start"
			>
				{!isDesktop && (
					<StickyBox offsetTop={70} offsetBottom={20}>
						{MenuBar &&
							MenuBar.map((item: string, i: number) => (
								<Box
									key={i}
									sx={{
										width: '100%',
										px: {
											xs: '0px',
											md: '2rem',
										},
									}}
								>
									<Button
										onClick={() => executeScroll(i)}
										sx={{
											justifyContent: 'flex-start',
											textAlign: 'start',
											borderLeft: activeTab() === i ? '3px solid #2E90FA' : '',
										}}
									>
										{item}
									</Button>
								</Box>
							))}
					</StickyBox>
				)}
			</Grid>
			<Grid
				item
				xs={12}
				md={9}
				sm={9}
				display="flex"
				sx={{
					padding: {
						xs: '0px',
						md: '0px 32px',
					},
				}}
				mb="30px"
				flexDirection="column"
				justifyContent="flex-start"
				// sx={{ background: '#F2F4F7' }}
			>
				<Box ref={DescriptionRef}>
					{value && (
						<Box display="flex" flexWrap="wrap" gap={{ xs: 1, lg: 2 }} mb={4}>
							{value?.tags?.map((items: any, id: number) => (
								<Button key={id} sx={{ ...ButtonDescriptionStyling, color: items.color }} size="small">
									{items?.name}
								</Button>
							))}
						</Box>
					)}
					<Typography
						ref={scrollToDescriptionRef}
						fontSize="24px"
						sx={{
							lineHeight: {
								md: '32px',
								xs: '24px',
							},
							fontWeight: '600',
							fontFamily: 'Inter',
							letterSpacing: '-0.01em',
						}}
					>
						Description & Function
					</Typography>
					<Typography
						mt={2}
						sx={{
							lineHeight: {
								md: '30px',
								sm: '30px',
								xs: '20px',
							},
							color: colors.gray[500],
							fontFamily: 'Inter',
							fontWeight: '400',
							fontSize: '16px',
							fontStyle: 'normal',
						}}
					>
						{descriptionText ? <p dangerouslySetInnerHTML={{ __html: descriptionText }}></p> : null}
					</Typography>
				</Box>
				<Box ref={PotentialRef} mt={3}>
					<Typography
						ref={scrollToPotentialRef}
						fontSize="24px"
						sx={{
							lineHeight: {
								md: '32px',
								xs: '24px',
							},
							fontWeight: '600',
							fontFamily: 'Inter',
							letterSpacing: '-0.01em',
						}}
					>
						Potential Application
					</Typography>
					<Typography
						variant="subtitle1"
						mt={2}
						sx={{
							lineHeight: {
								md: '30px',
								sm: '30px',
								xs: '20px',
							},
							color: colors.gray[500],
							fontFamily: 'Inter',
							fontSize: '16px',
						}}
					>
						<span style={{ fontWeight: 'bold' }}>{value && value?.location?.title}</span>
					</Typography>
					{((value?.location?.location_img_url &&
						value?.location?.location_img_url !== 'https://api-dev.sjadaptationtoolkit.com/') ||
						value?.location?.image) && (
						<Box maxWidth="xl">
							<img
								src={isOnline ? value?.location?.location_img_url : value?.location?.image}
								style={{ margin: '1em 0', width: '100%', borderRadius: '5px' }}
								alt="demo"
								height="inherit"
							/>
						</Box>
					)}
				</Box>

				<Box ref={TechnicalRef} mt={3}>
					<Typography
						ref={scrollToTechnicalRef}
						fontSize="24px"
						sx={{
							lineHeight: {
								md: '32px',
								xs: '24px',
							},
							marginTop: '30px',
							fontWeight: '600',
							fontFamily: 'Inter',
							letterSpacing: '-0.01em',
							mt: '2em',
						}}
					>
						Potential Combination with Other Measures
					</Typography>
					<Container sx={{ px: '0px!important', mt: '2em' }}>
						{value &&
							value?.potentials?.map((item: any) => (
								<ul key={item.id}>
									<li
										style={{
											color: '#667085',
											fontFamily: 'Inter',
											letterSpacing: '-0.01em',
											fontSize: '16px',
											fontWeight: '400',
										}}
									>
										<Link to={`${ROUTES.MEASUREITEM}?id=${item.id}`} target="_blank" style={{ color: 'inherit ' }}>
											{item.name}
										</Link>
									</li>
								</ul>
							))}
					</Container>
				</Box>
				<Box ref={ScoringRef} mt={2} maxWidth="md">
					<>
						<Typography
							ref={scrollToScoringRef}
							fontSize="24px"
							sx={{
								lineHeight: {
									md: '32px',
									xs: '24px',
								},
								fontWeight: '600',
								fontFamily: 'Inter',
								letterSpacing: '-0.01em',
							}}
						>
							Scoring
						</Typography>
						<Box display={'flex'} gap={3} mt={3}>
							<Button
								variant="outlined"
								sx={{
									width: '200px',
									py: 1,
									...(scoringViewMeasure === 'criteria' ? { borderColor: 'grey !important', color: 'grey' } : {}),
								}}
								onClick={() => setScoringViewMeasure('pillar')}
							>
								Pillars
							</Button>
							<Button
								variant="outlined"
								sx={{
									width: '200px',
									py: 1,
									...(scoringViewMeasure === 'pillar' ? { borderColor: 'grey !important', color: 'grey' } : {}),
								}}
								onClick={() => setScoringViewMeasure('criteria')}
							>
								Criteria
							</Button>
						</Box>
						{scoringViewMeasure === 'pillar' && (
							<TableStyle>
								{!isDesktop && (
									<thead>
										<tr>
											{MenuTablePillarMeasure.map((items: string) => (
												<th key={items} scope="col">
													{items}
												</th>
											))}
										</tr>
									</thead>
								)}
								<tbody>
									{criteriaList
										?.filter((it: any) => it?.is_scoring_guide)
										?.map((items: any, id: number) => {
											const data = value?.criteria_types?.find(
												(it: any) => it?.id === items?.id
											)?.measure_criteria_type;
											return !isDesktop ? (
												<>
													<tr key={items?.id}>
														<td scope="row">{items?.name}</td>
														<td>
															<RatingCustom value={data?.point || 0} />
														</td>
														<td>{data?.description || 'No description available'}</td>
													</tr>
												</>
											) : (
												<table style={{ width: '100%', marginRight: '0px' }}>
													<tr>
														<th
															key={items?.name}
															style={{
																display: 'flex',
																border: 'none',
															}}
														>
															<Accordion
																square
																sx={{
																	p: '0 !important',
																	border: 'none',
																	borderTop: '0 !important',
																	minHeight: '41px',
																	backgroundColor: 'unset',
																	boxShadow: 'none',
																	display: 'flex',
																	flexDirection: 'column',
																	justifyContent: 'center',
																	width: '100%',
																}}
															>
																<AccordionSummary
																	sx={{
																		width: '100%',
																		p: '8px!important',
																		minHeight: 'unset !important',
																		'.MuiAccordionSummary-content': { m: '0!important' },
																	}}
																	expandIcon={
																		<IconButton sx={{ p: 0, border: '1px solid gray' }}>
																			<KeyboardArrowDownIcon sx={{ fontSize: '12px' }} />
																		</IconButton>
																	}
																>
																	<Box
																		display="flex"
																		alignItems="center"
																		width="100%"
																		mr={0.5}
																		sx={{ cursor: 'default' }}
																		onClick={e => e.stopPropagation()}
																	>
																		<Typography mr={1} sx={{ flex: 1 }} textAlign="left">
																			{items?.name}
																		</Typography>
																		<RatingCustom value={data?.point} />
																	</Box>
																</AccordionSummary>
																<AccordionDetails sx={{ px: 1, pb: 1, pt: 0.5, color: 'gray', textAlign: 'left' }}>
																	{data?.description || 'No description available'}
																</AccordionDetails>
															</Accordion>
														</th>
													</tr>
												</table>
											);
										})}
								</tbody>
							</TableStyle>
						)}
						{scoringViewMeasure === 'criteria' && (
							<TableStyle>
								{!isDesktop && (
									<thead>
										<tr>
											{MenuTable.map((items: string) => (
												<th key={items} scope="col">
													{items}
												</th>
											))}
										</tr>
									</thead>
								)}
								<tbody>
									{criteriaList
										?.filter((it: any) => it?.is_scoring_guide && it?.criterias?.some((x: any) => x.is_scoring_guide))
										?.map((items: any, id: number) =>
											!isDesktop ? (
												<>
													<th
														key={items.name}
														rowSpan={items.criterias.filter((x: any) => x.is_scoring_guide).length + 1}
														scope="rowgroup"
													>
														{items.name}
													</th>
													{items.criterias
														?.filter((c: any) => c?.is_scoring_guide)
														.map((c: any) => {
															const principle = value?.criterias?.find((it: any) => it.id === c.id)?.measure_criteria;
															return (
																<tr key={c.id}>
																	<td scope="row">{c.principle}</td>
																	<td>
																		<RatingCustom value={principle?.point || 0} />
																	</td>
																	<td>{principle?.description || 'No description available'}</td>
																</tr>
															);
														})}
												</>
											) : (
												<table style={{ width: '100%', marginRight: '0px' }}>
													<tr>
														<th
															key={items?.name}
															style={{
																display: 'flex',
																border: 'none',
															}}
														>
															{items?.name}
														</th>
													</tr>
													{items.criterias
														?.filter((c: any) => c?.is_scoring_guide)
														.map((options: any, idx: number) => {
															const principle = value?.criterias?.find(
																(it: any) => it.id === options.id
															)?.measure_criteria;
															return (
																<>
																	<tr key={options.id} style={{ border: '1px solid #F0F0F0' }}>
																		<Accordion
																			square
																			sx={{
																				p: '0 !important',
																				border: 'none',
																				borderTop: '0 !important',
																				minHeight: '41px',
																				backgroundColor: 'unset',
																				boxShadow: 'none',
																				display: 'flex',
																				flexDirection: 'column',
																				justifyContent: 'center',
																			}}
																		>
																			<AccordionSummary
																				sx={{
																					width: '100%',
																					p: '8px!important',
																					minHeight: 'unset !important',
																					'.MuiAccordionSummary-content': { m: '0!important' },
																				}}
																				expandIcon={
																					<IconButton sx={{ p: 0, border: '1px solid gray' }}>
																						<KeyboardArrowDownIcon sx={{ fontSize: '12px' }} />
																					</IconButton>
																				}
																			>
																				<Box
																					display="flex"
																					alignItems="center"
																					width="100%"
																					mr={0.5}
																					sx={{ cursor: 'default' }}
																					onClick={e => e.stopPropagation()}
																				>
																					<Typography mr={1} sx={{ flex: 1 }} textAlign="left">
																						{options.principle}
																					</Typography>
																					<RatingCustom value={principle?.point} />
																				</Box>
																			</AccordionSummary>
																			<AccordionDetails
																				sx={{ px: 1, pb: 1, pt: 0.5, color: 'gray', textAlign: 'left' }}
																			>
																				{principle?.description || 'No description available'}
																			</AccordionDetails>
																		</Accordion>
																	</tr>
																</>
															);
														})}
												</table>
											)
										)}
								</tbody>
							</TableStyle>
						)}
					</>
					{!isDesktop && (
						<Button
							onClick={() => setmodalOpen(true)}
							sx={{
								border: '1px solid #D0D5DD',
								background: '#FFFFFF',
								boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
								my: '1em',
								px: '1em',
								color: '#344054',
							}}
						>
							View scoring pillar &nbsp;
							<ArrowOutwardRoundedIcon fontSize="medium" />
						</Button>
					)}
				</Box>
				<Box ref={ApplicationExampleRef} mt={3} maxWidth="xl">
					<Typography
						ref={scrollToApplicationExampleRef}
						fontSize="24px"
						mb={2}
						sx={{
							lineHeight: {
								md: '32px',
								xs: '24px',
							},
							fontWeight: '600',
							fontFamily: 'Inter',
							letterSpacing: '-0.01em',
						}}
					>
						Application Examples
					</Typography>
					{value && value?.applications && <Carousels data={value?.applications} />}
				</Box>
			</Grid>
			<Modal isOpen={modalOpen} handleClose={() => setmodalOpen(false)}>
				<Box sx={{ p: '20px' }}>
					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Typography
							fontSize="36px"
							sx={{
								lineHeight: {
									md: '24px',
									xs: '18px',
								},
								mt: '30px',
								fontWeight: '500',
							}}
						>
							SCORING GUIDE
						</Typography>
						<Button sx={{ fontSize: '20px', color: 'black' }} onClick={() => setmodalOpen(false)}>
							X
						</Button>
					</Box>
					<Box display={'flex'} gap={3} mt={6}>
						<Button
							variant="outlined"
							sx={{
								width: '200px',
								py: 1,
								...(scoringView === 'criteria' ? { borderColor: 'grey !important', color: 'grey' } : {}),
							}}
							onClick={() => setScoringView('pillar')}
						>
							Pillars
						</Button>
						<Button
							variant="outlined"
							sx={{
								width: '200px',
								py: 1,
								...(scoringView === 'pillar' ? { borderColor: 'grey !important', color: 'grey' } : {}),
							}}
							onClick={() => setScoringView('criteria')}
						>
							Criteria
						</Button>
					</Box>
					{scoringView === 'pillar' && (
						<TableStyle>
							<thead>
								<tr>
									{MenuTablePillar.map((items: string) => (
										<th key={items} style={{ color: '#9DA4AE' }} scope="col">
											{items}
										</th>
									))}
									<th scope="col" style={{ color: '#9DA4AE' }} colSpan={5}>
										SCORING GUIDE
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td></td>
									<td></td>
									{[1, 2, 3, 4, 5].map((num: number, id: number) => (
										<td>
											<RatingCustom key={id} value={num} align="center" />
										</td>
									))}
								</tr>
								{criteriaList &&
									criteriaList !== undefined &&
									criteriaList
										.filter((it: any) => it.is_scoring_guide)
										.map((item: any, id: number) => (
											<Fragment key={item.id}>
												<tr key={item}>
													<td scope="row">{item.name}</td>
													<td scope="row">{item.description}</td>
													<td scope="row">{item.scoring_guide[1]}</td>
													<td scope="row">{item.scoring_guide[2]}</td>
													<td scope="row">{item.scoring_guide[3]}</td>
													<td scope="row">{item.scoring_guide[4]}</td>
													<td scope="row">{item.scoring_guide[5]}</td>
												</tr>
											</Fragment>
										))}
							</tbody>
						</TableStyle>
					)}
					{scoringView === 'criteria' && (
						<TableStyle>
							<thead>
								<tr>
									{MenuTableCriteria.map((items: string) => (
										<th key={items} style={{ color: '#9DA4AE' }} scope="col">
											{items}
										</th>
									))}
									<th scope="col" style={{ color: '#9DA4AE' }} colSpan={5}>
										SCORING GUIDE
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td></td>
									<td></td>
									<td>
										<strong>Criteria on the positive scoring scale</strong>
									</td>
									{[1, 2, 3, 4, 5].map((num: number, id: number) => (
										<td>
											<RatingCustom key={id} value={num} align="center" />
										</td>
									))}
								</tr>
								{criteriaList &&
									criteriaList !== undefined &&
									criteriaList
										.filter((it: any) => it.is_scoring_guide)
										.map(
											(items: any, id: number) =>
												!!items.criterias?.filter((it: any) => it.is_scoring_guide)?.length && (
													<>
														<th key={items.id} rowSpan={items.criterias?.length + 2} scope="rowgroup">
															{items.name}
														</th>
														<tr></tr>
														{items &&
															items.criterias?.map((res: any, key: number) =>
																res.is_scoring_guide ? (
																	<>
																		<tr key={res.title}>
																			<td scope="row">{res.principle}</td>
																			<td scope="row">{res.definition}</td>
																			<td scope="row">{res.scoring_guide[1]}</td>
																			<td scope="row">{res.scoring_guide[2]}</td>
																			<td scope="row">{res.scoring_guide[3]}</td>
																			<td scope="row">{res.scoring_guide[4]}</td>
																			<td scope="row">{res.scoring_guide[5]}</td>
																		</tr>
																	</>
																) : (
																	<tr key={res.title}></tr>
																)
															)}
													</>
												)
										)}
							</tbody>
						</TableStyle>
					)}
				</Box>
			</Modal>
		</Grid>
	);
};

export default MeasureItemDetail;
