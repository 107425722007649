import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { ADMIN_ROUTES } from 'routers/consts';

export interface Introduction {
	id: string;
	name: string;
	description: string;
	color: string;
	slug?: string;
}

interface CriteriaListProps {
	data: Introduction[];
	onUpdate: (arg?: Introduction) => void;
	onDelete: (arg?: Introduction) => void;
}

const IntroductionList = (props: CriteriaListProps) => {
	const { data, onUpdate, onDelete } = props;

	return (
		<>
			{data
				?.sort((a, b) =>
					(b.slug === 'functionality' || b.slug === 'land_use_profiles') &&
					!(b.slug === 'functionality' && a.slug === 'land_use_profiles')
						? 1
						: -1
				)
				?.map((criteria: Introduction) => {
					return (
						<Box
							key={criteria.id}
							sx={{
								maxWidth: 856,
								marginBottom: '16px',
								border: '1px solid #EAECF0',
								borderRadius: '12px',
								boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.06)',
							}}
						>
							<Box display="flex" justifyContent="space-between" sx={{ padding: '24px 24px 0px 24px' }}>
								<Box display="flex" alignItems="center">
									<Typography sx={{ fontSize: 18, fontWeight: 600 }}>{criteria.name}</Typography>
									{criteria.color && (
										<Box ml={1} width="16px" height="16px" borderRadius={'50%'} bgcolor={criteria.color}></Box>
									)}
								</Box>

								<Box>
									<IconButton onClick={() => onUpdate(criteria)}>
										<FiEdit2 fontSize="medium" />
									</IconButton>
									{criteria.slug !== 'functionality' && criteria.slug !== 'land_use_profiles' && (
										<IconButton onClick={() => onDelete(criteria)}>
											<FiTrash2 fontSize="medium" />
										</IconButton>
									)}
								</Box>
							</Box>
							<Box
								sx={{
									padding: '0px 24px 24px 24px',
									borderBottom: '1px solid #EAECF0',
								}}
							>
								<Typography sx={{ fontSize: 14, fontWeight: 600, color: '#6C737F' }}>{criteria.description}</Typography>
							</Box>
							<Box sx={{ padding: '16px 24px', a: { textDecoration: 'none' } }}>
								<Link to={`${ADMIN_ROUTES.FILTER.replace(':id', criteria.id)}`}>
									<Typography sx={{ fontSize: 14, fontWeight: 600 }}>View Categories</Typography>
								</Link>
							</Box>
						</Box>
					);
				})}
		</>
	);
};

export default IntroductionList;
