import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, CardContent, Container, Grid, IconButton, Typography } from '@mui/material';
import { CloseSVG } from 'assets/icons';
import Image from 'components/atoms/Image';
import { AccordionCustomCompare } from 'components/molecules';
import { useCompareContext } from 'providers/context';
import * as React from 'react';
import { useRef, useState } from 'react';
import Carousel from 'react-multi-carousel';
import { colors } from 'styles';
import MeasurePopup from '../MeasurePopup/index';

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 1,
		paritialVisibilityGutter: 60,
		slidesToSlide: 1,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1,
		paritialVisibilityGutter: 50,
		slidesToSlide: 1,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		paritialVisibilityGutter: 30,
		slidesToSlide: 1,
	},
};

const CompareTab: React.FC = () => {
	const pointScrollTo = useRef<HTMLDivElement>(null);

	const { compareData, handleRemoveCompareData } = useCompareContext();

	const [toggle, setToggle] = useState<number>();
	const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});
	const [expandedScoring, setExpandedScoring] = useState<{ [key: string]: boolean }>({});

	const handleExpand = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(prev => {
			prev[panel] = isExpanded;
			return { ...prev };
		});
	};

	const handleExpandScoring = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpandedScoring(prev => {
			prev[panel] = isExpanded;
			return { ...prev };
		});
	};

	const ButtonGroupSub = ({ goToSlide, previous, next, ...rest }: any) => {
		const {
			carouselState: { currentSlide },
		} = rest;

		return (
			<div
				style={{
					background: 'white',
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					marginTop: '10px',
					alignItems: 'center',
				}}
			>
				<Button key={1} sx={{ visibility: currentSlide === 0 ? 'hidden' : 'visible' }} onClick={() => previous()}>
					<ArrowBackIcon sx={{ color: colors.gray[500] }} />
				</Button>
				<Box px={1} overflow="hidden" textOverflow="ellipsis" whiteSpace="pre">
					{rest && rest.data && rest?.data[currentSlide]?.name}
				</Box>
				<Button
					sx={{ visibility: currentSlide === rest?.data?.length - 1 ? 'hidden' : 'visible' }}
					onClick={() => next()}
				>
					<ArrowForwardIcon sx={{ color: colors.gray[500] }} />
				</Button>
			</div>
		);
	};

	return (
		<Container maxWidth="lg">
			<Grid ref={pointScrollTo} container pl="0px" mb="100px" alignItems="flex-start" overflow="auto" flexWrap="nowrap">
				<>
					{['1', '2', '3'].map((option: any, id: number) => (
						<Grid
							key={id}
							item
							xs={12}
							md={4}
							sm={4}
							display="flex"
							flexDirection="column"
							justifyContent="center"
							sx={{
								display: {
									xs: 'inline-block',
									md: 'flex',
								},
								minWidth: '300px',
								flex: 1,
							}}
						>
							{compareData[id] ? (
								<Box
									sx={{
										border: '1px dashed #E5E7EB',
										height: '100%',
										mx: '0.5em',
										position: 'relative',
									}}
								>
									<IconButton
										onClick={() => handleRemoveCompareData(compareData[id] as any, id)}
										sx={{
											position: 'absolute',
											top: '5px',
											right: '5px',
										}}
									>
										<img src={CloseSVG} alt="close_icon" />
									</IconButton>
									<CardContent
										sx={{
											display: 'flex',
											alignContent: 'flex-start',
											flexDirection: 'column',
										}}
									>
										<Typography
											sx={{
												fontFamily: 'Inter',
												fontWeight: '700',
												fontSize: '12px',
												letterSpacing: '0.08em',
												textTransform: 'uppercase',
											}}
											gutterBottom
											component="div"
											color={compareData[id]?.category?.color}
										>
											{compareData[id]?.category?.name}
										</Typography>
										<Typography
											sx={{
												fontSize: '24px',
												textOverflow: 'ellipsis',
												overflow: 'hidden',
												whiteSpace: 'nowrap',
												fontFamily: 'Inter',
												fontWeight: '600',
											}}
										>
											{compareData[id]?.name}
										</Typography>
										<Box display="flex" alignItems="flex-end" justifyContent="space-between">
											<Typography
												sx={{ mt: '20px', fontFamily: 'Inter', fontWeight: '600', fontSize: '16px' }}
												gutterBottom
												component="div"
											>
												Image type
											</Typography>
										</Box>
										<Box height="186px">
											{compareData[id]?.attachments?.length ? (
												<Carousel
													responsive={responsive}
													autoPlay={false}
													arrows={false}
													renderButtonGroupOutside={true}
													customButtonGroup={<ButtonGroupSub data={compareData[id]?.attachments} />}
												>
													{compareData[id]?.attachments.map((item, i) => (
														<Image
															key={i}
															style={{
																width: '100%',
																height: '140px',
																margin: 'auto',
															}}
															src={item.image}
														/>
													))}
												</Carousel>
											) : (
												<Box
													height="100%"
													display="flex"
													justifyContent="center"
													alignItems="center"
													color="gray"
													fontStyle="italic"
												>
													No photos
												</Box>
											)}
										</Box>
									</CardContent>
									<Container maxWidth="xl" sx={{ p: '0px!important' }}>
										<AccordionCustomCompare
											res={compareData[id] as any}
											expanded={expanded}
											expandedScoring={expandedScoring}
											handleExpand={handleExpand}
											handleExpandScoring={handleExpandScoring}
										/>
									</Container>
								</Box>
							) : (
								<Box
									sx={{
										border: '1px dashed #E5E7EB',
										height: '300px',
										mx: '0.5em',
										display: 'flex',
										background: '#F3F4F6',
										justifyContent: 'center',
										alignItems: 'flex-start',
										mr: '5px',
									}}
								>
									<Button
										sx={{
											color: 'black',
											margin: 'auto',
											background: '#FFFFFF',
											p: '5px 25px',
											border: '1px solid #D0D5DD',
											borderRadius: '6px',
											boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
										}}
										onClick={() => {
											setToggle(id);
										}}
									>
										+ Add Measure
									</Button>
								</Box>
							)}
						</Grid>
					))}
				</>
			</Grid>
			<MeasurePopup open={toggle} onClose={() => setToggle(undefined)} />
		</Container>
	);
};

export default CompareTab;
