import { Box, Button, Container, Grid, Hidden, Typography } from '@mui/material';
import {
	how_to_use_1,
	how_to_use_2,
	how_to_use_3,
	how_to_use_4,
	icon_basis_for_thinking,
	icon_characterised_criterias,
	icon_impact_management,
} from 'assets/icons';
import { ReactComponent as ClockWiseIcon } from 'assets/icons/clockwise-arrow.svg';
import { ReactComponent as CounterClockWiseIcon } from 'assets/icons/counter-clockwise-arrow.svg';
import { main_toolkit, main_toolkit_background } from 'assets/images';
import { Layout } from 'components/organisms';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { colors } from 'styles';

interface IntroducePageProps {}
type UserMenuProp = {
	title: string;
	description: string;
	iconSrc?: string;
};

const objectives = [
	{
		title: 'Understand how to compare measures',
		description:
			'Characterise potential measures using technical (performance), economic, environmental and social (stakeholders) values',
		iconSrc: icon_characterised_criterias,
	},
	{
		title: 'Learn how to maximise benefits',
		description:
			'Establish appreciation of the effectiveness of measures to manage risks and potential benefits and impact of measures',
		iconSrc: icon_impact_management,
	},
	{
		title: 'Prioritise current needs & future opportunities',
		description:
			'Establish the basis for priorities and decision-making through the understanding of risk threshold, development limits, future and interim needs and opportunities.',
		iconSrc: icon_basis_for_thinking,
	},
];
const HowToUse = [
	{
		title: 'Understand the types of categories and filters',
		description: 'Start with our Adaptation Measures tab and gain a deeper appreciation of the measures.',
		iconSrc: how_to_use_1,
	},
	{
		title: 'Select the type of development profile most similar to yours',
		description:
			'Explore the measures starting from your development type and look into its typology and functionality.',
		iconSrc: how_to_use_2,
	},
	{
		title: `Shortlisted a few? Let's compare them!`,
		description:
			'Comparison between measures of the same functionality guide you into selecting measures during the adaptation pathways planning stage.',
		iconSrc: how_to_use_3,
	},
	{
		title: 'Make a decision',
		description: 'Feeling confident in selecting a type of adaptation measure after the process?',
		iconSrc: how_to_use_4,
	},
];
const overallItems = [
	{
		content: 'Review of Case Studies',
		color: '#d1e9ffb3',
		scale: 1.1,
	},
	{
		content: 'Development of Adaptation Toolkit measures',
		color: '#FEC84B',
		scale: 1.25,
		zIndex: 10,
	},
	{
		content: 'Modeling of Do-Nothing scenarios',
		color: '#d1e9ffb3',
		scale: 1.1,
	},
	{
		content: 'Formulation of Adaptation Pathways',
		color: '#f3f4f6c9',
		scale: 1.1,
	},
	{
		content: 'Multi-Pillar Decision Analysis (MCDA) & Cost-Benefit Analysis (CBA)',
		color: '#d1e9ffb3',
		scale: 1.1,
	},
	{
		content: 'Final Recommendation Adaptation Pathways',
		color: '#f3f4f6c9',
		scale: 1.1,
	},
];

const ToolkitPage: React.FC<IntroducePageProps> = ({}) => {
	const navigate = useNavigate();

	return (
		<Layout>
			{/* Main start */}
			<Container
				maxWidth="xl"
				sx={{
					textAlign: 'center',
					p: '0px',
					background: 'linear-gradient(112.33deg, #EFF8FF -2.8%, #FFFFFF 61.03%, #D1E9FF 124.92%)',
					padding: '0px!important',
				}}
			>
				<Grid container textAlign="center" justifyContent="center">
					<Grid item xs={9} md={9} sm={9}>
						<Typography
							variant="h3"
							fontWeight="bold"
							lineHeight="60px"
							fontSize="48px"
							sx={{
								marginTop: {
									md: '100px',
								},
							}}
						>
							Adaptation Toolkit for Northwest Coast (Coastal Reservoirs)
						</Typography>
					</Grid>
					<Box
						sx={{
							backgroundImage: `url(${main_toolkit_background})`,
							width: '100%',
							backgroundRepeat: 'no-repeat',
							objectFit: 'cover',
							backgroundSize: 'cover',
						}}
					>
						<Box maxWidth="md" component="div" m="50px auto 0">
							<img
								src={main_toolkit}
								style={{
									borderTopLeftRadius: '15px',
									borderTopRightRadius: '15px',
									width: '100%',
									padding: '18px 15px 0 15px',
									background: 'black',
								}}
								alt="demo"
								height="inherit"
							/>
						</Box>
					</Box>
				</Grid>
			</Container>
			{/* Main End */}

			{/* How to use Start */}
			<Container
				maxWidth="md"
				sx={{
					textAlign: 'center',
					mt: '70px',
				}}
			>
				<Typography
					sx={{
						fontFamily: 'Inter',
						fontWeight: '600',
						fontSize: '16px',
						lineHeight: '24px',
						color: '#2E90FA',
					}}
					mb="12px"
				>
					HOW TO USE
				</Typography>
				<Typography
					variant="h4"
					fontWeight={600}
					fontSize="36px"
					mb="24px"
					sx={{
						lineHeight: {
							md: '40px',
							sm: '40px',
							xs: '30px',
						},
						fontFamily: 'Inter',
					}}
				>
					Easy way to pick the best measure
				</Typography>
				<Typography
					variant="subtitle1"
					sx={{
						lineHeight: {
							md: '30px',
							sm: '30px',
							xs: '20px',
						},
						color: colors.gray[500],
					}}
				>
					This Adaptation Toolkit aims to provide an understanding of these complexities before the detailed planning
					process begins, and it is tailored for the Northwest Coast (Coastal Reservoirs) of Singapore
				</Typography>
			</Container>
			<Container
				maxWidth="lg"
				sx={{
					textAlign: 'center',
					mt: '70px',
				}}
			>
				<Grid spacing={4} container textAlign="center" justifyContent="center">
					{HowToUse &&
						HowToUse.map((item: UserMenuProp, i: number) => (
							<Grid item xs={12} md={3} sm={6}>
								<img style={{ width: '100%' }} src={item.iconSrc} alt="logo" />
								<Box>
									<Typography
										variant="subtitle1"
										fontWeight="600"
										sx={{
											lineHeight: {
												md: '24px',
												sm: '24px',
												xs: '20px',
											},
											height: '50px',
											fontFamily: 'Inter',
											fontSize: '16px',
											color: '#000000',
										}}
									>
										{i + 1}. {item.title}
									</Typography>
									<Typography
										variant="subtitle1"
										fontWeight="400"
										sx={{
											lineHeight: {
												md: '20px',
												sm: '20px',
												xs: '18px',
											},
											fontFamily: 'Inter',
											fontSize: '14px',
											mt: '10px',
											color: '#9DA4AE',
										}}
									>
										{item.description}
									</Typography>
								</Box>
							</Grid>
						))}
				</Grid>
				<Link to="/categories" state={{ tab: 0 }} style={{ width: '100%' }}>
					<Button
						variant="contained"
						sx={{
							my: '70px',
							px: '2.5rem',
							width: {
								xs: '100%',
								md: '200px',
							},
							padding: {
								xs: '12px 20px',
								md: '6px 16px',
							},
						}}
					>
						Use Toolkit Now
					</Button>
				</Link>
			</Container>
			{/* How to use End */}

			<Container maxWidth="xl" sx={{ padding: '0px!important' }}>
				{/* Objective start */}
				<Container
					maxWidth="xl"
					sx={{
						background: '#F9FAFB',
					}}
				>
					<Container
						maxWidth="lg"
						sx={{
							textAlign: 'center',
							py: '100px',
							pl: `0 !important`,
							pr: '0 !important',
						}}
					>
						<Typography
							sx={{
								fontFamily: 'Inter',
								fontWeight: '600',
								fontSize: '16px',
								lineHeight: '24px',
								color: '#2E90FA',
							}}
							mb="12px"
						>
							OBJECTIVES
						</Typography>
						<Typography
							variant="h4"
							fontWeight={600}
							fontSize="24px"
							mb="24px"
							mt="5px"
							sx={{
								lineHeight: {
									md: '32px',
									sm: '32px',
									xs: '26px',
								},
								fontFamily: 'Inter',
							}}
						>
							This toolkit seeks to strengthen the overall understanding of the benefits and trade-offs for individual
							measures. The practical benefits and feasibility of these measures will then be evaluated in subsequent
							steps of the adaptation planning process.
						</Typography>
						<Grid mt="30px" spacing={6} container textAlign="center" justifyContent="center">
							{objectives &&
								objectives.map((item: UserMenuProp, i: number) => (
									<Grid item xs={12} md={4} sm={6}>
										<img src={item.iconSrc} alt="logo" />
										<Typography
											variant="subtitle1"
											fontWeight="600"
											sx={{
												lineHeight: {
													md: '24px',
													sm: '24px',
													xs: '20px',
												},
												fontFamily: 'Inter',
												fontSize: '16px',
												mt: '10px',
												height: '50px',
												color: '#384250',
											}}
										>
											{item.title}
										</Typography>
										<Typography
											mt="10px"
											variant="subtitle1"
											sx={{
												lineHeight: {
													md: '30px',
													sm: '30px',
													xs: '20px',
												},
												color: colors.gray[500],
												fontFamily: 'Inter',
												fontSize: '16px',
											}}
										>
											{item.description}
										</Typography>
									</Grid>
								))}
						</Grid>
					</Container>
				</Container>
				{/* Objective End */}
				{/* Overall Start */}
				<Container sx={{ padding: '50px 0px 0px 0px !important', mt: '10px' }} maxWidth="xl">
					<Container
						maxWidth="md"
						sx={{
							textAlign: 'center',
							// mt: '100px',
						}}
					>
						<Typography
							sx={{
								fontFamily: 'Inter',
								fontWeight: '600',
								fontSize: '16px',
								lineHeight: '24px',
								color: '#2E90FA',
							}}
							mb="12px"
						>
							WHERE DOES THE TOOLKIT FIT?
						</Typography>
						<Typography
							variant="h4"
							fontWeight={600}
							fontSize="40px"
							mb="24px"
							mt="5px"
							sx={{
								lineHeight: {
									md: '48px',
									xs: '40px',
								},
								fontFamily: 'Inter',
								color: '#111927',
							}}
						>
							Overall Planning Context
						</Typography>
						<Typography
							variant="subtitle1"
							fontSize="16px"
							sx={{
								lineHeight: {
									md: '30px',
									sm: '30px',
									xs: '20px',
								},
								color: colors.gray[500],
								fontFamily: 'Inter',
							}}
						>
							This Adaptation Toolkit serves as a key component of the overall adaptation planning process shown below.
							It forms an important basis for carrying out the subsequent planning work and detailed analytical process
							to map out the long-term adaptation pathways for the Northwest Coast (Coastal Reservoirs) of Singapore.
						</Typography>
					</Container>
					{/* Overall End */}
					<Box
						maxWidth="lg"
						component="div"
						m="20px auto"
						pt={{ xs: '40px', md: '100px' }}
						pb={{ xs: '60px', md: '200px' }}
						display="flex"
						alignItems="center"
						flexDirection={{ xs: 'column', md: 'row' }}
					>
						{overallItems.map((it, id, array) => (
							<Box
								key={id}
								display="flex"
								flexDirection="column"
								justifyContent="center"
								alignItems="center"
								borderRadius="50%"
								position="relative"
								sx={{
									backgroundColor: it.color,
									width: { xs: '250px', md: `calc(100% / 6)` },
									paddingBottom: { xs: '250px', md: `calc(100% / 6)` },
									height: 0,
									position: 'relative',
									transform: {
										xs: `translateY(-${id !== 0 ? (30 * id) / (it.scale * 2) : 0}px) scale(${it.scale})`,
										md: `translate(-${
											id % 2 ? (1 / it.scale) * (id - 1 || 1) * 4 : id === 0 ? 0 : (1 / it.scale) * (id - 1) * 4
										}%, ${id % 2 ? 30 / it.scale : 0}%) scale(${it.scale})`,
									},
									zIndex: { xs: id + 1, md: id + 1 + (it.zIndex || 0) },
									flexShrink: 0,
								}}
							>
								{!(id % 2) && (
									<Hidden mdDown>
										<Box
											position="absolute"
											top={`${6 / array[id + 1].scale}%`}
											right={`-${34 / array[id + 1].scale}%`}
										>
											<ClockWiseIcon />
										</Box>
									</Hidden>
								)}
								{!!(id % 2 && id !== overallItems.length - 1) && (
									<Hidden mdDown>
										<Box
											position="absolute"
											bottom={`${6 / array[id + 1].scale}%`}
											right={`-${26 / array[id + 1].scale}%`}
										>
											<CounterClockWiseIcon />
										</Box>
									</Hidden>
								)}
								<Typography
									fontSize={{ xs: '16px', lg: '14px', xl: '16px' }}
									fontWeight={400}
									textAlign="center"
									sx={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										width: '67%',
										transform: 'translate(-50%, -50%)',
									}}
								>
									{it.content}
								</Typography>
							</Box>
						))}
					</Box>
				</Container>
				{/* Objective End */}
			</Container>
		</Layout>
	);
};

export default ToolkitPage;
