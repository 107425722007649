interface Item {
	id: string;
	name: string;
	color: string;
}

interface Tag extends Item {
	description: string;
	image: string;
}

export enum TAG_SLUG {
	FUNCTIONALITY = 'functionality',
}

export interface TagType {
	id: string;
	can_explore: boolean;
	description: string;
	is_master_data: boolean;
	is_multi_choice: boolean;
	name: string;
	slug: string;
	tags: Tag[];
}

enum ATTACHMENT {
	SCHEMATIC = 1,
	ILLUSTRATIONS,
}

interface Attachment extends Item {
	image: string;
	type: ATTACHMENT;
}

export class Measure {
	id: string;
	category_id: string;
	description: string;
	functions: string;
	main_picture: string;
	name: string;
	category: Item;
	attachments: Attachment[];
	potentials: Item[];
	tags: Tag[] & { tag_type_id: string; color: string };
	technical_consider: {
		design: string;
		implementation: string;
		operation: string;
		planning: string;
	};
	location: {
		id: string;
		title: string;
		description: string;
		image: string;
	};
	criterias: {
		id: string;
		principle: string;
		scoring_guide: { [key: string]: string };
		measure_criteria: {
			point: number;
			description: string;
		};
		type: number;
	}[];
	criteria_types: {
		id: string;
		scoring_guide: { [key: string]: string };
		measure_criteria_type: {
			point: number;
			description: string;
		};
		type: number;
	}[];
	all_tags: string[];

	constructor(data: any = {}) {
		this.id = data?.id;
		this.category_id = data?.category_id;
		this.description = data?.description;
		this.functions = data?.functions;
		this.main_picture = data?.main_picture;
		this.name = data?.name;
		this.technical_consider = data?.technical_consider || {};
		this.location = data?.location || {};
		this.category = data?.category || {};
		this.tags = data?.tags || [];
		this.potentials = data?.potentials || [];
		this.attachments = data?.attachments || [];
		this.criterias = data?.criterias || [];
		this.criteria_types = data?.criteria_types || [];
		this.all_tags = this.tags.map(tag => tag.id).concat(this.category.id);
	}
}

export interface Criteria {
	id: string;
	definition: string;
	principle: string;
	is_scoring_guide: boolean;
	scoring_guide: {
		[key: number]: string;
	};
}

export interface CriteriaCategory {
	id: string;
	name: string;
	description?: string;
	criterias: Criteria[];
	is_scoring_guide: boolean;
}
