import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Box, Grid, Hidden, IconButton, Pagination, PaginationItem, Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { MeasuresCard } from 'components/molecules/index';
import { Measure } from 'modules/measure';
import { NetworkContext, useCompareContext } from 'providers/context';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routers';

interface Props {
	data: Measure[];
	loading: boolean;
	handleShowFilterDialog: () => void;
}

const LIMIT_DATA = 10;

const MeasureItemList: React.FC<Props> = props => {
	const { data, loading, handleShowFilterDialog } = props;
	const { isOnline } = useContext(NetworkContext);
	const { handleAddCompareData } = useCompareContext();
	const navigate = useNavigate();
	const anchor = useRef<HTMLDivElement | null>(null);
	const [page, setPage] = useState(1);

	const filterData = useMemo(() => {
		return data.slice((page - 1) * LIMIT_DATA, (page - 1) * LIMIT_DATA + LIMIT_DATA);
	}, [page, data]);

	useEffect(() => {
		if (loading) {
			setPage(1);
		}
	}, [loading]);

	const handlePageChange = (_: any, value: number) => {
		setPage(value);
		anchor.current?.scrollIntoView();
	};

	const onCompare = (measure: typeof data[0]) => {
		handleAddCompareData(measure);
		navigate(ROUTES.CATEGORIES, { state: { tab: 2 } });
	};

	return (
		<Stack>
			<Box display="flex" justifyContent="space-between" alignItems="center" ref={anchor}>
				<Typography>
					Showing{' '}
					<Typography fontWeight="bold" color="primary" component="span">
						{data.length} measures
					</Typography>{' '}
					<Hidden mdDown>suitable with the selected attributes</Hidden>
				</Typography>
				<Hidden mdUp>
					<IconButton
						onClick={handleShowFilterDialog}
						sx={{ borderRadius: '8px', border: 1, borderColor: '#D0D5DD', p: '7px' }}
					>
						<FilterListIcon />
					</IconButton>
				</Hidden>
			</Box>
			{loading ? (
				<Box mt={6} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
					<CircularProgress size="4em" />
				</Box>
			) : (
				<Box mt={6}>
					{!filterData.length && <Typography textAlign="center">No results</Typography>}
					<Grid container spacing={4}>
						{filterData.map((item, i) => (
							<Grid item key={i} xs={12} md={6} display="flex" flexDirection="column" justifyContent="center">
								<MeasuresCard
									data={item}
									image_url={
										isOnline ? (item?.attachments?.[0] as any)?.attachment_image_url : item?.attachments?.[0]?.image
									}
									handleCompare={onCompare}
								/>
							</Grid>
						))}
					</Grid>
				</Box>
			)}
			<Pagination
				hidden={!filterData.length}
				count={Math.ceil(data?.length / 10)}
				siblingCount={0}
				page={page}
				renderItem={item => (
					<PaginationItem
						components={{
							next: props => (
								<Box display="flex" alignItems="center">
									<Hidden smDown>
										<span>Next</span>
									</Hidden>
									<KeyboardArrowRight />
								</Box>
							),
							previous: props => (
								<Box display="flex" alignItems="center">
									<KeyboardArrowLeft />
									<Hidden smDown>
										<span>Previous</span>
									</Hidden>
								</Box>
							),
						}}
						{...item}
					/>
				)}
				color="primary"
				size="large"
				sx={{
					pt: 5,
					pb: 5,
					'& .MuiPagination-ul': {
						justifyContent: 'center',
					},
				}}
				onChange={handlePageChange}
			/>
		</Stack>
	);
};

export default MeasureItemList;
