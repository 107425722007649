import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import { FieldFormik, Fieldrow, RatingCustomAdmin, Textarea } from 'components/atoms';
import { FieldArray, Formik } from 'formik';
import { useCallback } from 'react';

import IOSSwitch from 'components/atoms/IOSSwitch';
import { criteriaSchema, defaultInitialCriteriaValues } from './criteriaSchema';

const CriteriaModal = (props: any) => {
	const { isOpen = false, onCancel, onOk, initialValues } = props;

	const handleSubmit = useCallback(
		async (values: any, action: any) => {
			try {
				onOk && (await onOk(values));
			} catch (err) {
				action.setSubmitting(false);
			}
		},
		[onOk]
	);

	return (
		<Dialog open={isOpen} onClose={onCancel} fullWidth={true} maxWidth="md">
			<DialogTitle>
				{initialValues ? 'Edit' : 'Add'} Pillar
				<IconButton
					onClick={onCancel}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: theme => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Formik
					initialValues={initialValues || defaultInitialCriteriaValues}
					validationSchema={criteriaSchema}
					onSubmit={handleSubmit}
				>
					{({ isValid, dirty, isSubmitting, handleSubmit, values, setFieldValue }) => {
						return (
							<form onSubmit={handleSubmit}>
								<Stack spacing={2}>
									<Fieldrow name="name">
										<FieldFormik
											type="text"
											label="Pillar name"
											placeholder="Enter a pillar name"
											name="name"
											readonly={false}
											maxLength={127}
										/>
									</Fieldrow>
									<Fieldrow name="description">
										<Textarea
											value={values.description}
											label="Description"
											name="description"
											placeholder="Enter a description..."
											maxLength={500}
										/>
									</Fieldrow>
								</Stack>
								{values.is_scoring_guide && (
									<Box mt={2}>
										<Typography sx={{ fontWeight: 'bold', fontSize: 18 }}>Score Guide</Typography>
										<FieldArray
											name="scoring_guide"
											render={arrayHelpers => (
												<Box>
													{values.scoring_guide?.map((scoreGuide: any, idx: number) => (
														<Box key={idx}>
															<Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
																<RatingCustomAdmin name="1" ids={'1'} readonly={true} value={idx + 1} />
															</Box>
															<Fieldrow name={`scoring_guide.${idx}`}>
																<Textarea
																	value={values.scoring_guide[idx]}
																	name={`scoring_guide.${idx}`}
																	placeholder="Enter a description..."
																	maxLength={500}
																/>
															</Fieldrow>
														</Box>
													))}
												</Box>
											)}
										/>
									</Box>
								)}
								<Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mt="10px">
									<FormControlLabel
										control={
											<IOSSwitch
												sx={{ m: 1 }}
												checked={values.is_scoring_guide}
												onChange={(_: any, checked: boolean) => {
													setFieldValue('is_scoring_guide', checked);
												}}
											/>
										}
										label="Show scoring guide"
									/>
									<Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
										<Button variant="outlined" onClick={onCancel}>
											Cancel
										</Button>
										<LoadingButton
											variant="contained"
											type="submit"
											loading={isSubmitting}
											disabled={!dirty || !isValid}
										>
											Save
										</LoadingButton>
									</Box>
								</Box>
							</form>
						);
					}}
				</Formik>
			</DialogContent>
		</Dialog>
	);
};

export default CriteriaModal;
