import { Box, Grid, Tab, Tabs } from '@mui/material';
import { TAG_SLUG, useGetMeasures, useGetTagTypes } from 'modules/measure';
import { NetworkContext, useCompareContext } from 'providers/context';
import { useContext, useEffect, useMemo, useState } from 'react';
import { colors } from 'styles';
import MeasuresCard from '../MeasuresCard';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box mt={3} height="100%">
					{children}
				</Box>
			)}
		</div>
	);
}

interface HorizontalTabProps {
	position?: 'left' | 'center';
	containerSx?: any;
	contentSx?: any;
	itemIndex?: number;
	onClose: () => void;
}

const HorizontalTabCompare: React.FC<HorizontalTabProps> = ({
	position = 'center',
	containerSx = {},
	contentSx = {},
	itemIndex,
	onClose,
}) => {
	const { data: tagTypes = [] } = useGetTagTypes();
	const { data: measuresList = [] } = useGetMeasures();
	const { compareData, handleAddCompareData, handleShowCompareTab } = useCompareContext();

	const functionalityTags = useMemo(() => tagTypes.find(it => it.slug === TAG_SLUG.FUNCTIONALITY), [tagTypes.length]);
	const measureData = useMemo(() => {
		return functionalityTags?.tags?.map(tag => measuresList.filter(measure => measure.category.id === tag.id)) || [];
	}, [measuresList.length, functionalityTags]);

	const { isOnline } = useContext(NetworkContext);
	const [currentTab, setCurrentTab] = useState(0);

	useEffect(() => {
		const activeTab = compareData.filter(data => data)[0]?.category?.id;
		if (activeTab && functionalityTags) {
			setCurrentTab(functionalityTags.tags.findIndex(it => it.id === activeTab));
		}
	}, [compareData, functionalityTags]);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setCurrentTab(newValue);
	};

	const onCompare = (measure: typeof measuresList[0]) => {
		handleAddCompareData(measure, itemIndex);
		onClose();
	};

	return (
		<Box sx={{ ...containerSx, width: '100%' }}>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: colors.gray[200],
				}}
			>
				<Tabs value={currentTab} onChange={handleChange} centered={position === 'center'} variant="scrollable">
					{functionalityTags?.tags?.map(item => (
						<Tab key={item.id} label={item.name} />
					))}
				</Tabs>
			</Box>
			<Box
				sx={{
					...contentSx,
					backgroundColor: 'white',
				}}
			>
				{functionalityTags?.tags?.map((tag: any, index: number) => (
					<TabPanel key={tag.id} value={currentTab} index={index}>
						<Box p={{ xs: 0, md: 3 }} pt={0}>
							<Grid container spacing={3} position="relative">
								{measureData[index]?.map(item => (
									<Grid key={item.id} item md={4} xs={12}>
										<MeasuresCard
											disabled={
												!!compareData.find(data => data?.id === item.id) ||
												!!compareData.filter(data => data).find(data => data?.category?.id !== item.category.id)
											}
											data={item}
											image_url={
												isOnline ? (item as any)?.attachments?.[0]?.attachment_image_url : item?.attachments?.[0]?.image
											}
											handleCompare={onCompare}
											onCardClick={() => handleShowCompareTab(true)}
										/>
									</Grid>
								))}
							</Grid>
						</Box>
					</TabPanel>
				))}
			</Box>
		</Box>
	);
};

export default HorizontalTabCompare;
