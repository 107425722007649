import { array, object, string } from 'yup';

export const criteriaSchema = object({
	name: string().trim().required('Pillar name is required'),
	description: string(),
	scoring_guide: array().when(['is_scoring_guide'], {
		is: true,
		then: schema => schema.of(string().trim().required('Score guide is required')),
	}),
});

export const defaultInitialCriteriaValues = {
	name: '',
	description: '',
	is_scoring_guide: true,
	scoring_guide: ['', '', '', '', ''],
};
