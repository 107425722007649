// @ts-ignore
import { Axios } from 'core/httpServices';

export const downloadToolkit = async () => {
	const { data } = await Axios.get('/toolkits/download');
	const downloadUrl = data?.downloadUrl;
	if (downloadUrl) {
		const link = document.createElement('a');
		link.download = 'AdaptationToolkit';
		link.href = downloadUrl;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
	return data;
};
